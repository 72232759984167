function formatCurrensy(price) {
    if (price === 0) return 'Call for price';

    if (!price) return null;

    price = price.toFixed(price % 1 === 0 ? 0 : 2);

    return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export default formatCurrensy;
