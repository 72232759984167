import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Form from 'react-jsonschema-form';
import get from 'lodash.get';
import queryString from 'query-string';
import { Container, Button } from 'reactstrap';
import firebase from 'config/firebase';
import { toast } from 'react-toastify';
import { AppContext } from 'AppContext';
import { Typography } from 'lib';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils';

const RegisterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    width: 350px;
    border: 1px solid #eee;
    border-radius: 4px;
    flex-direction: column;
    padding: 25px 20px;
    margin: 40px 10px 10px 10px;
    background: #fff;
`;

const ButtonStyled = styled(Button)`
    font-size: 20px;
`;

const AuthProviderFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0;
`;

function Register(props) {
    const [state, setState] = useState({ isLoading: false });
    // eslint-disable-next-line react/prop-types
    const { history } = props;
    // get a hold of hte main `appContext`
    const appContext = useContext(AppContext);
    // get the params from the url
    const queryParams = queryString.parse(
        get(history, 'location.search')
    );

    function getSchema() {
        return {
            title     : 'Register',
            type      : 'object',
            className : 'text-center',
            properties: {
                email: {
                    title: 'Email',
                    type : 'string'
                },
                password: {
                    title: 'Password',
                    type : 'string'
                },
                confirmPassword: {
                    title: 'Confirm Password',
                    type : 'string'
                },
            },
            required: ['email', 'password', 'confirmPassword']
        };
    }

    function getUISchema() {
        return {
            email: {
                'ui:widget': 'email',
                'ui:help'  : 'We will use this email to communicate with you.'
            },
            password: {
                'ui:widget': 'password',
                'ui:help'  : 'Password should be at least 7 characters long. It should contain at least one uppercase, one lowercase, one digit and one special character.'
            },
            confirmPassword: {
                'ui:widget': 'password',
            }
        };
    }

    function validate(formData, errors) {
        const { password, confirmPassword } = formData;

        // validate the password length
        if (password.length < 7) {
            errors.password.addError(
                'Password should be at least 7 characters long.'
            );
        }

        // Password complexity requirements
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_\-+=]).*$/;
        if (!regex.test(password)) {
            errors.password.addError(
                'Password should include at least one uppercase letter, one lowercase letter, one digit, and one special character.'
            );
        }

        // validate the password and confirmPassword match
        if (password !== confirmPassword) {
            errors.confirmPassword.addError(
                'Passwords do not match.'
            );
        }

        return errors;
    }

    async function onSubmit({ formData }) {
        const {
            email, password
        } = formData;
        // eslint-disable-next-line no-unused-vars
        const [appState, dispatch] = appContext;

        setState({ isLoading: true });

        try {
            // create user in the firebase
            const { user } = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password);
            // get the new user's token
            const accessToken = await user.getIdToken();
            // build use object
            const userProfile = {
                email,
                uid          : user.uid,
                emailVerified: user.emailVerified,
                photoURL     : user.photoURL,
                password
            };
            // store a copy of the token local storage
            window.localStorage.setItem('accessToken', accessToken);
            // update the app state
            dispatch({
                type: 'USER_REGISTRATION_SUCCESS',
                data: { ...userProfile }
            });
            // track the event
            trackEvent('sign_up', { method: 'email' });
            // navigate to verify the accound
            history.push(`/auth/verify?plan=${queryParams.plan}&category=${queryParams.category}&onboarding=true`);
        } catch (error) {
            const { code } = error;
            // stop the message
            setState({ isLoading: false });
            // respond to the errors
            switch (code) {
            case 'auth/weak-password':
                toast.warning('Please provide a stronger password. Adding the following characters can help: .!@#$*');
                break;

            case 'auth/invalid-email':
                toast.warning('The provided email is not valid.');
                break;

            case 'auth/email-already-in-use':
                toast.warning(
                    'The provided email is already in use or not valid.'
                );
                break;

            default:
                break;
            }
        }
    }

    return (
        <Container>
            <RegisterWrapper>
                <Content>
                    <Form
                        disabled={state.isLoading}
                        showErrorList={false}
                        schema={getSchema()}
                        uiSchema={getUISchema()}
                        validate={validate}
                        onSubmit={onSubmit}
                    >
                        <ButtonStyled
                            disabled={state.isLoading}
                            color="danger"
                            className="text-white font-weight-bold"
                            block
                        >
                            {state.isLoading ? 'Creating account...' : 'Create Account'}
                        </ButtonStyled>
                        <Typography color="#636363" marginTop="20px" fontSize="14px">
                            By clicking <strong>Create Account</strong>, you agree to the <Link to="/terms">Terms Of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
                        </Typography>
                        <hr />
                        <Typography color="#636363" fontSize="14px">
                            Already have an account?{' '}<Link to="/auth/login">Login</Link>
                        </Typography>
                    </Form>
                    <AuthProviderFormWrapper id="auth-container" />
                </Content>
            </RegisterWrapper>
        </Container>
    );
}

Register.propTypes = {
    history: PropTypes.object.isRequired
};

export default Register;
