import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Footer } from 'components';
import { Container,Jumbotron, Button, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { NUMBER_OF_FREE_MONTHS } from 'constants/app';
import queryString from 'query-string';
import get from 'lodash.get';
import { Typography } from 'lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 50px;
    font-weight: bold;
`;

const LeadText = styled.p`
    font-size: 20px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;

    @media(max-width: 990px) {
        width: 100%;
    }
`;

function Learn(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // get from the props history the params
    const queryParams = queryString.parse(
        get(props, 'history.location.search')
    );

    function navigate() {
        const { history } = props;
        history.push('/contact');
    }

    function renderLearnMore() {
        return (
            <>
            <div style={{ height: isMobile ? 'auto' : 'calc(100vh - 140px)' }}>
                <Container>
                    <Jumbotron className="bg-transparent text-center mb-0">
                        <HeroText>Get more customers.<br /> Grow your business.</HeroText>
                        <LeadText>
                        At 343Trucking.com, we're driving the future of the trucking industry by connecting service providers, mechanics, welders, truck sales, and more with the right opportunities.
                        </LeadText>
                        <Button
                            color="danger"
                            className="mr-2 pl-5 pr-5"
                            onClick={navigate}
                        >
                            Get Started
                        </Button>
                    </Jumbotron>
                </Container>            
                <Container className="bg-dark text-white pt-5 pb-5 mt-2 mb-2" fluid>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Typography
                                type="h5"
                                fontWeight="normal"
                            >
                                How we help you
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Typography type="h4">
                                Competitive Pricing
                            </Typography>
                            <p className="fs-16">
                            We understand the importance of affordability in today's market. That's why we offer competitive pricing to ensure you get the best value for your investment.
                            </p>
                        </Col>
                        <Col md="4">
                            <Typography type="h4">
                                Premium Visibility
                            </Typography>
                            <p className="fs-16">
                            Stand out from the crowd with our premium pricing option. Your listings will appear ahead of others in search results, increasing your chances of connecting with the right customers.
                            </p>
                        </Col>
                        <Col md="4">
                            <Typography type="h4">
                                Relevant Results
                            </Typography>
                            <p className="fs-16">
                                Our platform is powered by cutting-edge technology designed to provide your customers with the most relevant results.
                            </p>
                        </Col>
                    </Row>
                </Container>
                </Container>
                <Container className="pt-5 pb-5" fluid>
                    <Container>
                        <Row>
                            <Col md="12" className="text-center">
                                <Typography type="h3">
                                    Ready to extend your reach to new customers?
                                </Typography>
                                <p className="fs-18 mb-0">
                                    Get your first 30 days <b>FREE</b>!
                                </p>
                                <p width="60%" margin="auto">
                                    <Button
                                        color="danger"
                                        className="mt-3"
                                        size="lg"
                                        onClick={navigate}
                                    >
                                        Get Started today!
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                </div>
            </>
        );
    }

    function renderListYourBusiness() {
        return (
            <div style={{ height: isMobile ? 'auto' : 'calc(100vh - 140px)' }}>
                <Container>
                    <Jumbotron className="bg-transparent text-center mb-0">
                        <HeroText>List your business.</HeroText>
                        <LeadText>
                            <p>Get <strong>{NUMBER_OF_FREE_MONTHS} months of free advertising</strong> today!</p>
                            No credit card required. No commitment. No hidden fees.
                            We will contact you before your free trial ends to see if you want to continue advertising with us.
                            If you do, we will send you an invoice for the next month.
                            If you don&apos;t, we will remove your listing from our website.
                            It&apos;s that simple.
                        </LeadText>
                        <div>
                            <ul style={{ textAlign: 'left' }}>
                                <li>
                                    <strong>$24.99 / Monthly</strong> - includes your business name, address, phone number, website link, description of your business, social media links, and upon request we can include up to 10 pictures.{' '}
                                </li>
                            </ul>
                        </div>
                        <p>Don't worry about picking a plan today. <br /> You'll get a chance at the end of your <strong>{NUMBER_OF_FREE_MONTHS} FREE</strong> months.</p>
                        <button className="btn btn-dark pt-2 pb-2 pl-5 pr-5" onClick={() => props.history.push('/contact')}>
                            Get started
                        </button>
                        <div className="text-muted fs-12 mt-3">* This offer is for a limited only.</div>
                    </Jumbotron>
                </Container>
            </div>
        );
    }

    function renderSellYourTruck() {
        return (
            <Container className=".container-details">
                <Jumbotron className="bg-transparent text-center mb-0">
                    <HeroText>Sell your truck, trailer or other equipment with us today!</HeroText>
                    <LeadText>
                        <p>Get your listing in front of truckers and owner operators.</p>
                    </LeadText>
                    <div className="mb-4">
                        <p className="fs-24"><strong>$24.99 / <span className="text-muted fs-16 fw-normal">One time fee</span></strong></p>
                        <ul style={{ textAlign: 'left', width: '375px', margin: 'auto' }}>
                            <li className="list-style-none"><FontAwesomeIcon icon={faCheckCircle} /> Listing runs for 60 days</li>
                            <li className="list-style-none"><FontAwesomeIcon icon={faCheckCircle} /> Relist for another 30 if not sold</li>
                            <li className="list-style-none"><FontAwesomeIcon icon={faCheckCircle} /> Up to 30 pictures</li>
                            <li className="list-style-none"><FontAwesomeIcon icon={faCheckCircle} /> Add a video link or YouTube url</li>
                            <li className="list-style-none"><FontAwesomeIcon icon={faCheckCircle} /> Include a link back to your website</li>
                        </ul>
                        <p className="mt-3">Enhanced listing with a badge for an additional $5 (optional)</p>
                    </div>
                    <button
                        className="btn btn-dark pt-2 pb-2 pl-5 pr-5"
                        onClick={() => props.history.push('/contact?category=selling-your-truck')}
                    >
                        Get started
                    </button>
                    <div className="text-muted fs-12 mt-3">* 100% privacy guarantee.</div>
                </Jumbotron>
                {/* create a section that centers for FQA */}
                <div className="row">
                    <div className="col-md-6 margin-auto">
                        <h2 className="text-center">Frequently Asked Questions</h2>
                        <div className="mt-5 mb-5">
                            <h5>How do I sell my truck?</h5>
                            <p>
                                At the moment, we will need to create your listing for you. Send us information about your truck, trailer or other equipment that you wish to sell. We will send you a link for you to review.
                            </p>
                            <h5>Can I make changes or modifications to my listing?</h5>
                            <p>Yes, you can request as many changes as you wish. Once you are happy with your listing, we are ready to go live.</p>
                            <h5>How do I pay for my listing?</h5>
                            <p>
                                We will send you an invoice for your listing. You will have option to select a payment method. We will activate your listing once we receive payment.
                            </p>
                            <h5>How do I get my listing enhanced?</h5>
                            <p>
                                For an additional $5 dollars, you can add a badge to your listing. This helps visitors draw their eyes to it as it helps your listing stand out.
                            </p>
                            <h5>How can I tell my ad is enhanced?</h5>
                            <p>
                                Your listing will feature a badge like this one <span className="badge badge-warning mr-1">Sponsored</span>
                            </p>
                            <h5>Why can't I create my own listing?</h5>
                            <p>We are currently working on adding this feature.</p>
                            <h5>Still have questions?</h5>
                            <p><Link to="/contact">Contact us</Link> with your question or concerns.</p>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }

    function getPageContent() {
        switch (queryParams.category) {
            case 'selling-your-truck':
                return renderSellYourTruck();
            case 'list-your-business':
                return renderLearnMore();
            default:
                return renderListYourBusiness();
        }
    }

    return (
        <>
            {getPageContent()}
            <Footer />
        </>
    );
}

Learn.propTypes = {
    history: PropTypes.object
};

Learn.defaultProps = {
    history: { push: () => { } }
};

export default Learn;
