/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Label, Input
} from 'reactstrap';

function getSortSelected(options, parsed) {
    options.forEach((element) => {
        if (parsed.sort === element.value) {
            element.checked = true;
        }
    });
    return options;
}

function FiltersModal(props) {
    const {
        options, isOpen, toggle, title, handleOnChange, handleOnSubmit, location: { search }
    } = props;
    // FIXME:
    // this parsed stuff should come from the parent
    // this modal should not care about all of this.
    const parsed = queryString.parse(search);
    const [state, setState] = useState({
        selectedValue: parsed.sort,
        sortOptions  : getSortSelected(options, parsed)
    });

    function onChange({ target }) {
        const { id } = target;
        // eslint-disable-next-line no-param-reassign
        let selectedValue = '';
        state.sortOptions.forEach((option) => {
            if (option.key === id) {
                selectedValue = option.value;
            }
        });
        setState({
            selectedValue,
            sortOptions: state.sortOptions
        });
        handleOnChange(selectedValue);
        toggle();
    }

    function removeFilters() {
        handleOnSubmit();
        toggle();
    }

    return (
        <Modal isOpen={isOpen} fade={false} toggle={toggle} backdrop centered>
            <ModalHeader toggle={toggle}>{title ? title.toUpperCase() : 'Filters'}</ModalHeader>
            <ModalBody>
                <ListGroup flush>
                    {state.sortOptions.map((option) => (
                        <ListGroupItem key={option.key}>
                            <Label style={{ width: '100%', padding: '5px 0' }}>
                                <Input
                                    id={option.key}
                                    type="radio"
                                    name="sort-by"
                                    checked={state.selectedValue === option.value}
                                    defaultValue={option.value}
                                    onChange={onChange}
                                />{' '} {option.label}
                            </Label>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </ModalBody>
            {handleOnSubmit && (
                <ModalFooter>
                    <Button color="primary" onClick={removeFilters}>Remove Filters</Button>
                </ModalFooter>
            )}
        </Modal>
    );
}

FiltersModal.propTypes = {
    title         : PropTypes.string,
    toggle        : PropTypes.func.isRequired,
    isOpen        : PropTypes.bool,
    handleOnChange: PropTypes.func.isRequired,
    handleOnSubmit: PropTypes.func,
    location      : PropTypes.object.isRequired,
    options       : PropTypes.array
};

FiltersModal.defaultProps = {
    title         : '',
    isOpen        : false,
    options       : [],
    handleOnSubmit: null
};

export default FiltersModal;
