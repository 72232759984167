import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash.get';
import { Spinner } from 'reactstrap';
import { callFunc } from 'utils/helpers';
import isEmpty from 'lodash.isempty';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { TableBodyStyled, TableCell, TableRow } from './styled';
import TableActions from './TableActions';
import TableCellImage from './CustomCellTypes/TableCellImage';

function TableBody(props) {
    const {
        data, isLoading, schema, location
    } = props;
    // get the columns
    const columns = get(schema, 'table.columns', []);
    // get the custom components for the cellTypes, if defined
    const cellTypes = get(schema, 'cellTypes', {});
    // combine the custom cell with the different table cell types
    cellTypes.TableActions = TableActions;
    cellTypes.TableCellImage = TableCellImage;

    function getNoResultMessage() {
        const params = queryString.parse(location.search);
        let message = '';
        if (params.brand && params.model) {
            message = `${params.brand} ${params.model}`;
        } else if (params.search) {
            message = `${params.search}`;
        } else {
            message = 'your query';
        }

        return (
            <div>
                No results found for <strong>{message}</strong>
                <Link to="/admin/inventory?limit=25&page=1" className="ml-2">
                    view all
                </Link>
            </div>
        );
    }

    if (isLoading) {
        return (
            <TableBodyStyled className="pt-5">
                <TableRow>
                    <TableCell>
                        <Spinner size="sm" color="secondary" /> Loading...
                    </TableCell>
                </TableRow>
            </TableBodyStyled>
        );
    }

    return (
        <TableBodyStyled>
            {!isEmpty(data) ? data.map((item) => (
                <TableRow key={item.id}>
                    {columns.map((column) => {
                        const component = cellTypes[column.type];
                        return (
                            <TableCell key={`column__${column.label}`}>
                                {component
                                    ? callFunc(component, {
                                        ...props,
                                        column,
                                        row: { ...item }
                                    })
                                    : item[column.key]}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))
                : (
                    <TableRow>
                        <TableCell>
                            {getNoResultMessage()}
                        </TableCell>
                    </TableRow>
                )}
        </TableBodyStyled>
    );
}

TableBody.propTypes = {
    data     : PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    schema   : PropTypes.object.isRequired,
    location : PropTypes.object
};

TableBody.defaultProps = {
    data     : [],
    isLoading: false,
    location : {}
};

export default TableBody;
