import PropTypes from 'prop-types';
import React, { useState } from 'react';
import get from 'lodash.get';
import queryString from 'query-string';
import AsyncSelect from 'react-select/async';
import { isMobile, BrowserView } from 'react-device-detect';
import { Accordion } from 'lib';
import { Nav, NavItem } from 'reactstrap';
import SearchBox from 'components/HomeSearch/SearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';

import {
    AsyncSelectWrapper,
    FiltersStyled,
    NavLinkStyled,
    OverflowControl
} from './styled';

const MINIMUN_OPTIONS = 10;

function Filters(props) {
    const { handlers, params } = props;
    // get the current url parameters.
    const queryParams = queryString.parse(
        get(props, 'history.location.search', {})
    );
    // component state
    const [state, setState] = useState({
        expandedId    : `accordion__${queryParams.brand}`,
        isMakeOpened  : true,
        isModelOpen   : false,
        isPartTypeOpen: false
    });
    // find all of the makes
    const handleSetQuery = get(handlers, 'handleSetQuery');
    // const handleResetFilter = get(handlers, 'handleResetFilter');
    const isSearching = Boolean(get(props, 'history.location.pathname', '') === '/classifieds/search');
    const autoModels = [];

    // //////////////////////////////

    function handleOnClick(config) {
        handleSetQuery({
            ...params,
            ...config,
            sort: 'no-sort',
            page: 1
        });

        // We do this to collapse the select
        if (isMobile) {
            setState((prevState) => ({ ...prevState, isMakeOpened: !prevState.isMakeOpened }));
        }
    }

    function isSelected(value) {
        if (queryParams.subCategory === value) {
            return true;
        }
        return false;
    }

    // function handleOnClearFilters() {
    //     let isMakeOpened = true;
    //     if (isMobile) {
    //         isMakeOpened = !state.isMakeOpened;
    //     }
    //     setState({ expandedId: '', isMakeOpened });
    //     callFunc(handleResetFilter());
    // }

    function filterModels(inputValue) {
        let options = [];
        options = autoModels.filter(
            (category) => category.toLowerCase().includes(inputValue.toLowerCase())
        );
        return options;
    }

    function loadModelOptions(inputValue) {
        return new Promise((resolve) => {
            // filter the array
            const result = filterModels(inputValue);
            // build the options
            const options = result.map((res) => ({ value: res, label: res }));

            resolve(options);
        });
    }

    function onSelectChange(event) {
        const query = {
            ...params,
            model: event ? event.value : 'delete',
            page : 1
        };
        handleSetQuery(query);
    }

    function accordionTitle() {
        const { category } = queryParams;
        const catList = get(props, 'schema.categories', []);
        const { label } = catList.find((cat) => cat.value === category) || { label: 'Trucking' };
        return (
            <div><FontAwesomeIcon icon={faThList} /> {label}</div>
        );
    }

    function onSubmitSearch(querySearch) {
        // eslint-disable-next-line react/prop-types
        props.history.push({
            pathname: '/classifieds/search',
            search  : querySearch
        });
    }

    function onClearSearch() {
        // eslint-disable-next-line react/prop-types
        const { history } = props;
        history.push({
            pathname: '/classifieds',
            search  : ''
        });
    }

    function buildAccordionSection(openState, groupName, categories, param, asyncParams = {}) {
        const isOpen = isMobile ? !state[openState] : state[openState];
        const { placeholder, defaultValue, showAsyncSelect } = asyncParams;
        return (
            <>
                <SearchBox
                    className="form-control"
                    placeholder="Search"
                    defaultValue={queryParams.search}
                    handlers={{
                        handleOnClearSearch: onClearSearch,
                        handleSearch       : onSubmitSearch
                    }}
                    styles={{
                        fontSize   : '1rem',
                        fontWeight : 400,
                        height     : '43px',
                        margin     : '5px 0',
                        borderColor: '#ced4da !important',
                        boxShadow  : 'none'
                    }}
                />
                {!isSearching && (
                    <BrowserView>
                        <Accordion
                            collapse={isOpen}
                            title={accordionTitle()}
                            onClick={
                                () => setState((prevState) => ({
                                    ...prevState, [openState]: !prevState[openState]
                                }))
                            }
                            content={(
                                <>
                                    {showAsyncSelect && (
                                        <AsyncSelectWrapper>
                                            <AsyncSelect
                                                isClearable
                                                cacheOptions
                                                defaultOption={[]}
                                                placeholder={placeholder}
                                                loadOptions={loadModelOptions}
                                                onChange={onSelectChange}
                                                defaultValue={defaultValue}
                                            />
                                        </AsyncSelectWrapper>
                                    )}
                                    <OverflowControl
                                        contentLen={categories.length}
                                        minOptions={MINIMUN_OPTIONS}
                                    >
                                        <Nav vertical>
                                            {
                                                categories.map((option) => {
                                                    const { value, label } = option;
                                                    const optionValue = value;
                                                    const index = `${groupName}__${optionValue}`;
                                                    const isOptionSelected = isSelected(optionValue);
                                                    return (
                                                        <NavItem key={index}>
                                                            <NavLinkStyled
                                                                color="link"
                                                                disabled={isOptionSelected}
                                                                className={isOptionSelected && 'filter-selected'}
                                                                onClick={() => handleOnClick({
                                                                    subCategory: optionValue
                                                                })}
                                                            >
                                                                {label}
                                                            </NavLinkStyled>
                                                        </NavItem>
                                                    );
                                                })
                                            }
                                        </Nav>
                                    </OverflowControl>
                                </>
                            )}
                        />
                    </BrowserView>
                )}
            </>
        );
    }

    function renderCategories() {
        const subCategoriesList = get(props, `schema.subCategories.${queryParams.category}`, []);
        const param = 'category';
        return buildAccordionSection('isMakeOpened', queryParams.category, subCategoriesList, param);
    }

    return (
        <FiltersStyled>
            {renderCategories()}
        </FiltersStyled>
    );
}

Filters.propTypes = {
    /**
     * This is the list of default paramters from the parent state.
     * It takes the current page because it's used internally for paging
     * thru records.
     */
    params  : PropTypes.object,
    handlers: PropTypes.object
};

Filters.defaultProps = {
    params  : {},
    handlers: {}
};

export default Filters;
