import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    Button,
    Badge
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';

import { Box } from 'lib';

const SubHeading = styled.span`
    font-size: 14px;
`;
const BadgeStyled = styled(Badge)`
    font-size: 12px;
    margin-left: 5px;
`;
const Heading = styled.h5`
    margin-left: 10px;
`;

function PageTitle(props) {
    const {
        subheading, title, badgeText, open, rightContent, ...rest
    } = props;

    return (
        <Box
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            background="transparent"
            {...rest}
        >
            {subheading && (
                <SubHeading className="text-muted text-small">
                    {subheading}
                </SubHeading>
            )}
            {typeof title === 'string' ? (
                <Heading className="mt-0" id="content">
                    {title}
                    {badgeText && (
                        <BadgeStyled>
                            {badgeText}
                        </BadgeStyled>
                    )}
                </Heading>
            ) : title}
            <div>
                <Button
                    color="link"
                    className="text-muted"
                    onClick={open}
                    style={{ fontSize: '14px' }}
                >
                    {rightContent} <FontAwesomeIcon icon={faSlidersH} className="ml-1" />
                </Button>
            </div>
        </Box>
    );
}

PageTitle.propTypes = {
    title     : PropTypes.any,
    subheading: PropTypes.string,
    badgeText : PropTypes.string,
    open      : PropTypes.func.isRequired
};

PageTitle.defaultProps = {
    title     : null,
    subheading: null,
    badgeText : null
};

export default PageTitle;
