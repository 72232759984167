import React from 'react';
import { Table as TableWrapper } from 'reactstrap';
import styled from 'styled-components';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const TableStyled = styled(TableWrapper)`
    font-size: 15px;
`;

function Table(props) {
    return (
        <TableStyled responsive>
            <TableHeader {...props} />
            <TableBody {...props} />
        </TableStyled>
    );
}

export default Table;
