import React, { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route } from 'react-router-dom';
import { Home } from 'pages';
// eslint-disable-next-line no-unused-vars
import { Header } from 'components';
import { Main } from 'styled';
import firebase from 'config/firebase';
import { getMostResentUserLocation, initData } from 'utils';
import { AppContext, reducer } from './AppContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const PrivacyPolicy = lazy(() => import('pages/SitePolicies/PrivacyPolicy'));
const TermsOfService = lazy(() => import('pages/SitePolicies/TermsOfService'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const Listings = lazy(() => import('pages/Listings'));
const Categories = lazy(() => import('pages/Categories'));
const DetailsNew = lazy(() => import('pages/Details/DetailsNew'));
const Learn = lazy(() => import('pages/Learn'));
const Profile = lazy(() => import('pages/Profile'));
const Pricing = lazy(() => import('pages/Pricing'));
const Admin = lazy(() => import('pages/Admin'));
const Success = lazy(() => import('pages/Subscription/Success'));
const Cancel = lazy(() => import('pages/Subscription/Cancel'));
const Auth = lazy(() => import('pages/Auth'));

const appState = {
    status      : 'FETCHING',
    user        : null,
    subscription: null,
    items       : {
        classifieds : [],
        totalRecords: 0,
        currentPage : 1
    }
};

getMostResentUserLocation();

function Routing() {
    const [state, dispatch] = React.useReducer(reducer, appState);
    React.useEffect(() => {
        dispatch({ type: 'GET_APPLICATION_DATA_START' });
        firebase.auth().onAuthStateChanged((authUser) => initData(authUser, dispatch));
    }, []);

    return (
        <AppContext.Provider value={[state, dispatch]}>
            <Header className="header" />
            <Main role="main">
                <Suspense fallback={<div>Loading...</div>}>
                    <Route exact path="/" component={Home} />
                    {/* <Route path="/auth" component={Auth} /> */}
                    <Route path="/admin" component={Admin} />
                    <Route path="/learn" component={Learn} />
                    {/* <Route path="/profile" component={Profile} /> */}
                    <Route path="/pricing" component={Pricing} />
                    {/* <Route path="/subscription/cancel" component={Cancel} /> */}
                    {/* <Route path="/subscription/success" component={Success} /> */}
                    <Route path="/terms" component={TermsOfService} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route path="/contact" component={ContactUs} />
                    <Route path="/listings/:category" component={Listings} />
                    <Route path="/search" component={Listings} />
                    <Route path="/categories" component={Categories} />
                    <Route
                        path="/details/:category/:id"
                        component={DetailsNew}
                    />
                </Suspense>
            </Main>
        </AppContext.Provider>
    );
}

function App() {
    return (
        <BrowserRouter>
            <Routing />
            <ToastContainer
                hideProgressBar
                pauseOnFocusLoss={false}
                className="toast-container"
            />
        </BrowserRouter>
    );
}

export default App;
