import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Footer } from 'components';
import {
    Container, Jumbotron
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 36px;
    font-weight: bold;
`;

function Cancel(props) {
    /**
     * Navigation
     */
    function navigate() {
        const { history } = props;
        history.push('/pricing');
    }
    return (
        <>
            <Container style={{ height: 'calc(100vh - 142px)' }}>
                <Jumbotron className="bg-transparent text-center mb-0">
                    <HeroText>Subscription cancelled</HeroText>

                    <Link
                        to="/"
                        className="btn btn-danger p-3 pl-5 pr-5 mt-3"
                        onClick={navigate}
                    >
                        <FontAwesomeIcon icon={faHome} /> Back to the home page
                    </Link>
                </Jumbotron>
            </Container>

            <Footer />
        </>
    );
}

Cancel.propTypes = {
    history: PropTypes.object
};

Cancel.defaultProps = {
    history: { push: () => { } }
};

export default Cancel;
