import styled from 'styled-components';
import { NavLink } from 'reactstrap';

const FiltersStyled = styled.div`
    background: #fff;
    border: 1px solid #eee;
    padding: 10px;
`;

const NavLinkStyled = styled(NavLink)`
    display: block;
    padding: 5px 20px;
    margin-bottom: 3px;
    color: #333;
    font-weight: 500;

    &:hover {
        background: #f5f5f5;
        border-radius: 6px;
    }
    ${({
        padding, color, fontSize, background
    }) => ({
        padding, color, fontSize, background
    })}
`;

const FilterAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const AsyncSelectWrapper = styled.div`
    padding: 10px;
`;

const OverflowControl = styled.div`
    // height: 250px;
    // overflow-y: scroll;
    overflow-x: hidden;

    ${({ contentLen, minOptions }) => {
        if (contentLen < minOptions) {
            return {
                height  : 'auto',
                overflow: 'auto'
            };
        }
        return {};
    }}
`;

export {
    AsyncSelectWrapper,
    FiltersStyled,
    NavLinkStyled,
    FilterAction,
    OverflowControl
};
