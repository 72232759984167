import React, { useContext } from 'react';
import { Route as RouteNav } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from 'components/Context';
import isEmpty from 'lodash/isEmpty';

function Route(props) {
    const { isPrivate, history } = props;
    const appContext = useContext(AppContext);
    const { user } = appContext;

    if (isEmpty(user) || isPrivate) {
        // redirect the user back to log in
        history.push('/login');
    }

    return <RouteNav {...props} />;
}

Route.propTypes = {
    isPrivate: PropTypes.bool,
    history  : PropTypes.object
};

Route.defaultProps = {
    isPrivate: false,
    history  : {}
};

export default Route;
