import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Footer } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
    Button, Card, CardBody, Container, Row, Col, Jumbotron, CardGroup, Badge
} from 'reactstrap';
import { Typography, Divider } from 'lib';
import { AppContext } from 'AppContext';
import { PLANS } from 'constants/app';

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 36px;
    font-weight: bold;
`;

const LeadText = styled.p`
    font-size: 20px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;

    @media(max-width: 990px) {
        width: 100%;
    }
`;

const CheckList = styled.div`
    display: flex;
    align-items: center;
`;

function scrollToTop() {
    window.scrollTo(0, 0);
}

function Pricing(props) {
    const [paymentInterval] = useState('monthly');
    const [appState] = useContext(AppContext);
    const { user } = appState;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    async function navigate(plan) {
        // const priceId = PLANS[plan.toUpperCase()].PLAN_ID;
        const USE_STRIPE = false;
        if (USE_STRIPE && user && plan !== 'free') {
            // const { email } = user;
            // create stripe object
            // const stripe = await stripePromise;
            // create the session for this user to use on stripe
            // const { data } = await Axios({
            //     method      : 'post',
            //     url         : `${API_URL}/api/billing/create-checkout-session`,
            //     responseType: 'json',
            //     data        : { email, priceId }
            // });
            // send them to Stripe's checkout page
            // const result = await stripe.redirectToCheckout({
            //     sessionId: data.id
            // });
            // respond to any error
            // if (result.error) {
            //     toast.error(result.error.message);
            // }
            // return;
        }
        props.history.push(
            `/auth/register?plan=${plan}&category=services`
        );
    }

    return (
        <>
            <Container>
                <Jumbotron className="bg-transparent text-center mb-0">
                    <HeroText>Join dozens of companies already reaching customers</HeroText>
                    <LeadText>
                        No contract required. Cancel anytime.
                    </LeadText>
                </Jumbotron>
                <Row>
                    <Col>
                        <CardGroup>
                            <Card>
                                <CardBody>
                                    <Typography type="h5" marginBottom="15px" fontWeight="bold">Free</Typography>
                                    <Typography margin="0 0 20px">Good for personal or small business that have one service.</Typography>
                                    <Typography type="h3">
                                        $0<span className="text-muted">/mo</span>
                                    </Typography>
                                    <Typography color="#6c757d!important">
                                        Always free
                                    </Typography>
                                    <Button
                                        block
                                        size="lg"
                                        color="dark"
                                        className="mt-2"
                                        onClick={() => navigate('free')}
                                    >
                                        GET STARTED
                                    </Button>
                                    <CheckList className="mt-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">1 Ad in General Category only</div>
                                    </CheckList>
                                    <CheckList>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Text only Ad</div>
                                    </CheckList>
                                </CardBody>
                            </Card>
                            <Card style={{ border: '2px solid rgb(40 167 69)' }}>
                                <CardBody>
                                    <Typography type="h5" marginBottom="15px" fontWeight="bold">Stardard <Badge color="success" style={{ color: 'white' }}>Best Value</Badge></Typography>
                                    <Typography margin="0 0 20px">Good if you sell other services. For example, selling parts, trucks, etc. </Typography>
                                    <Typography type="h3">
                                        {paymentInterval === 'monthly' ? <>$11.99<span className="text-muted">/mo</span></>
                                            : <>$9.99<span className="text-muted">/mo</span></>}
                                    </Typography>
                                    <Typography color="#6c757d !important" fontSize="13px">
                                        {paymentInterval === 'monthly' ? <>* Billed monthly at $11.99. Cancel any time.</>
                                            : <>Billed annually at $120. Save $24 each year.</>}
                                    </Typography>
                                    <Button
                                        block
                                        size="lg"
                                        color="dark"
                                        className="mt-2"
                                        onClick={() => navigate('standard')}
                                    >
                                        GET STARTED
                                    </Button>
                                    <CheckList className="mt-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Add your website and email</div>
                                    </CheckList>
                                    <CheckList>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Add your company logo</div>
                                    </CheckList>
                                    <CheckList>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Users can contact you through the site</div>
                                    </CheckList>
                                    <CheckList>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Create &amp; Display Offers</div>
                                    </CheckList>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Typography type="h5" marginBottom="15px" fontWeight="bold">Premium</Typography>
                                    <Typography margin="0 0 20px">Good for large businesses that needs more than 10 ads to advertise.</Typography>
                                    <Typography type="h3">
                                        {paymentInterval === 'monthly' ? <>$29.99<span className="text-muted">/mo</span></>
                                            : <>$24.99<span className="text-muted">/mo</span></>}
                                    </Typography>
                                    <Typography color="#6c757d!important" fontSize="13px">
                                        {paymentInterval === 'monthly' ? <>* Billed monthly at $29.99. Cancel any time.</>
                                            : <>Billed annually at $300. Save $60 each year.</>}
                                    </Typography>
                                    <Button
                                        block
                                        size="lg"
                                        color="dark"
                                        className="mt-2"
                                        onClick={() => navigate('premium')}
                                    >
                                        GET STARTED
                                    </Button>
                                    <CheckList className="mt-4">
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">All of the feature in the standard plan</div>
                                    </CheckList>
                                    <CheckList>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <div className="ml-2">Top placement above standard and free</div>
                                    </CheckList>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
                <Row className="mt-5 mb-4">
                    <Col>
                        <Typography textAlign="center" type="h4">Commonly asked questions</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" md="6">
                        <Typography type="h5">What is the difference in each plan?</Typography>
                        <Typography>
                            The free plan is good for businesses that are run by 1 person or more but don’t need to advertise other services or goods. The standard plan is good if you sell a few items. For example, you can be a truck dealership with less than 10 trucks for sale each month. The premium plan is good if you need to post more than 10 ads. If you sell parts, trucks, and anything beyond 10, this plan is perfect for you. For most people, the “Standard” plan will be the best option.
                        </Typography>
                        <Typography type="h5">How does creating an offer help my business?</Typography>
                        <Typography>
                            Let’s say that you create an offer that takes 10% of your service for first time customers.
                            When a customer finds you on our website, we will display your offer on your ad.
                            Customers that see discounted product or services are more likely to engage with your ad.
                        </Typography>
                        <Typography type="h5">What is top placement for my ad in premium?</Typography>
                        <Typography>
                            In this plan, your ad is placed above standard and basic. For example, if a user is looking for mechanics, your ad will be placed above the standard and basic ads. But we cannot guarantee that your ad will always be the first one.
                        </Typography>
                    </Col>
                    <Col xs="12" md="6">
                        <Typography type="h5">Can I cancel anytime?</Typography>
                        <Typography>
                            You can cancel at any time. We will refund your money. No questions asked. Please note that your ad will be downgraded to the Free plan, unless you choose to cancel all together.
                        </Typography>
                        <Typography type="h5">How can potential customers contact me through your site?</Typography>
                        <Typography>
                            Users of our site can email you directly with inquiries about your business. We recommend that you check your email and be ready to answer any questions they may have.
                        </Typography>
                    </Col>
                </Row>
            </Container>
            <Divider />
            <Container className="pt-5 pb-5" fluid>
                <Container>
                    <Row>
                        <Col md="12" className="text-center">
                            <Typography type="h3">
                                Ready to get more customers?
                            </Typography>
                            <Typography type="p" fontSize="18px">
                                Start for <span><strong>Free</strong></span> today. No Contract Required. Cancel at any time.
                            </Typography>
                            <Typography width="60%" margin="auto">
                                <Button
                                    color="dark"
                                    className="mt-3"
                                    size="lg"
                                    onClick={scrollToTop}
                                >
                                    Get Started
                                </Button>
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </>
    );
}

Pricing.propTypes = {
    history: PropTypes.object
};

Pricing.defaultProps = {
    history: { push: () => { } }
};

export default Pricing;
