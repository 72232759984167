/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { callFunc } from 'utils';

const Search = styled.input`
    width: 100%;
    height: 50px;
    border-width: 1px;
    border-color: #737373;
    font-size: 1rem;
    color: #000;
    position: relative;
    margin-bottom: 0px;
    box-shadow: rgba(22, 23, 26, 0.15) 0px 2px 8px;
    z-index: 3;
    padding: 10px 50px;
    transition: all 0.2s ease-out 0s;
    border-radius: 4px;

    ::placeholder {
        color: #737373;
    }

    :focus {
        outline: none;
        box-shadow: none;
    }

    ${({ styles }) => ({ ...styles })}
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
`;

const ButtonStyled = styled(Button)`
    position: absolute;
    padding: 15px 20px;
    color: #545454;
    z-index: 99;

    :hover: {
        cursor: pointer;
        border: 1px solid #eee;
    }
`;

function SearchBoxComp(props) {
    const {
        handlers, defaultValue, placeholder
    } = props;
    const { handleSearch } = handlers;
    const searchRef = React.useRef(null);

    function onSubmitSearch() {
        const { value } = searchRef.current;
        if (value.length < 3) {
            return;
        }
        callFunc(handleSearch, `search=${value}`);
    }

    function onClearSearch() {
        searchRef.current.value = '';
        window.history.back();
    }

    function onKeyUp(e) {
        if (e.keyCode === 13 && e.target.value.length > 2) {
            callFunc(handleSearch, `search=${searchRef.current.value}`);
        }
    }

    if (searchRef.current) {
        searchRef.current.value = defaultValue || '';
    }

    return (
        <SearchWrapper>
            <InputWrapper>
                <Search
                    placeholder={placeholder}
                    ref={searchRef}
                    onKeyUp={onKeyUp}
                />
                <ButtonStyled color="" onClick={onSubmitSearch} type="button" aria-label="search">
                    <FontAwesomeIcon icon={faSearch} />
                </ButtonStyled>
            </InputWrapper>
        </SearchWrapper>
    );
}

SearchBoxComp.propTypes = {
    handlers: PropTypes.object
};

SearchBoxComp.defaultProps = {
    handlers: {}
};

export default SearchBoxComp;
