function getApiUrl() {
    switch (window.location.hostname) {
    case '192.168.1.87':
    case 'localhost':
        return 'http://192.168.1.87:3003';
    case '343trucking.com':
        return 'https://us-west-2.aws.data.mongodb-api.com/app/truckingapplication-pzzgx/endpoint';
    default:
        return 'http://local.343trucking.com';
    }
}

function getApiUrlV2() {
    switch (window.location.hostname) {
    case '192.168.1.87':
    case 'localhost':
    case 'local.343trucking.com':
        return 'http://api.local.343trucking.com';
    default:
        return `https://api.${window.location.hostname}`;
    }
}

/**
 * Api URL for handleling requests
 */
const API_URL = getApiUrl();

/**
 * New endpoint
 */
const API_URL_V2 = getApiUrlV2();

const GOOGLE_MAP_ACCESS_KEY = 'AIzaSyA3rGKyaY9PEqlGqoeg9FErlIwv2KDHSsM';

export default API_URL;

export { API_URL, API_URL_V2, GOOGLE_MAP_ACCESS_KEY };
