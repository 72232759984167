import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Form from 'react-jsonschema-form';
import _get from 'lodash.get';

import { toast } from 'react-toastify';
import { Button, Container, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import firebase from 'config/firebase';
import {AppContext} from 'AppContext';
import { trackEvent } from 'utils';

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    width: 350px;
    border: 1px solid #eee;
    border-radius: 4px;
    flex-direction: column;
    padding: 25px 20px;
    margin: 65px 10px 10px 10px;
    background: #fff;
`;

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmiting: false
        };
    }

    componentDidMount() {
    }

    getFirebaseConfig = () => {
        // keep a copy of the global `this`
        const self = this;
        return {
            callbacks: {
                signInSuccessWithAuthResult(authResult) {
                    self.setUserInfo(authResult);
                }
            },
            signInFlow      : 'popup',
            signInSuccessUrl: '/',
            signInOptions   : [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
        };
    };

    setUserInfo = (firebaseUser) => {
        const { history } = this.props;
        // get the user from the context
        // const { dispatch } = this.context;
        const [, dispatch] = this.context;
        // get the `profile` from the result
        const userProfile = _get(firebaseUser, 'additionalUserInfo.profile');

        firebaseUser.user.getIdTokenResult().then((result) => {
            // get the user claims
            firebaseUser.user.getIdToken().then((accessToken) => {
                window.accessToken = accessToken;
                // store a copy of the token local storage
                window.localStorage.setItem('accessToken', accessToken);
                // store user info in localstorage
                window.localStorage.setItem('truckingData', JSON.stringify({ ...userProfile, claims: { superAdmin: result.claims.superAdmin } }));
                // success message
                toast.success('Success log in.', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                // update the user
                dispatch({ type: 'USER_LOGIN_SUCCESS', data: { ...userProfile, claims: result.claims } });
                // return to home page
                history.push('/');
                // refresh page
                window.location.reload();
            });
        });
    };

    getSchema = () => ({
        title     : 'Login',
        type      : 'object',
        className : 'text-center',
        properties: {
            username: {
                title: 'Email',
                type : 'string'
            },
            password: {
                title: 'Password',
                type : 'string'
            }
        },
        required: ['username', 'password'],
        uiSchema: {}
    });

    getUISchema = () => ({
        password: {
            'ui:widget': 'password'
        }
    });

    onSubmit = (formData) => {
        const {
            formData: { username, password }
        } = formData;

        this.setState({ isSubmiting: true });
        // login using `firebase`
        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then((response) => {
                // build a properti with userProfile
                const profile = {
                    displayName  : response.user.displayName,
                    email        : response.user.email,
                    emailVerified: response.user.emailVerified,
                    uid          : response.user.uid
                };
                // update response w/ new object
                response.additionalUserInfo.profile = profile;
                // set the user info
                this.setUserInfo(response);
                // stop spinner
                this.setState({ isSubmiting: false });

                trackEvent('login', { method: 'email' });
            })
            .catch((error) => {
                // get the code to determine the type of error
                const code = _get(error, 'code');
                // stop spinner
                this.setState({ isSubmiting: false });
                // handle the different codes
                switch (code) {
                case 'auth/wrong-password':
                    toast.error('Incorrect password. Please try again.', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                    break;
                case 'auth/user-not-found':
                    toast.error(
                        'Unable to find your account with the provided username and password. Please try again.',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT
                        }
                    );
                    break;

                default:
                    toast.error(
                        'Unable to verify your account. If problem persists, please try again or contact support.',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT
                        }
                    );
                    break;
                }
            });
    };

    render() {
        const { isSubmiting } = this.state;
        return (
            <Container>
                <LoginWrapper>
                    <Content>
                        <Form
                            disabled={isSubmiting}
                            schema={this.getSchema()}
                            uiSchema={this.getUISchema()}
                            onSubmit={this.onSubmit}
                        >
                            <Button
                                disabled={isSubmiting}
                                color="danger"
                                className="text-white font-weight-bold"
                                size="lg"
                                block
                            >
                                {isSubmiting ? (
                                    <div>
                                        <Spinner /> Submitting ...
                                    </div>
                                ) : ('Sign in')}
                            </Button>
                        </Form>
                        <p className="mt-3">
                            Forgot password?{' '}
                            <Link to="/auth/recover">Recover password</Link>
                        </p>
                        <p className="">
                            New user?{' '}
                            <Link to="/auth/register">Register</Link>
                        </p>
                        <p className="mb-0 mt-3 text-center text-muted">
                            - OR -
                        </p>
                    </Content>
                </LoginWrapper>
            </Container>
        );
    }
}

// set the context
Login.contextType = AppContext;

// props
Login.propTypes = {
    history: PropTypes.object
};

Login.defaultProps = {
    history: {}
};

export default Login;
