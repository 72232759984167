import styled from 'styled-components';

const Offer = styled.div`
    display: flex;
    padding: 5px;
    border: 1px dotted #707070;
    border-radius: 6px;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #4C4848;
    font-weight: 600;
`;

const Pointer = styled.div`
    width: 40px;
    height: 20px;
    position: relative;
    background: #6c757d!important;
    color: white;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;

    :after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
    }

    :before {
        content: "";
        position: absolute;
        right: -16px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 16px solid #6c757d!important;
        border-top: 10.4px solid transparent;
        border-bottom: 10px solid transparent;
    }
`;

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 50px;
    font-weight: bold;
    ${(props) => ({
        'font-size': props.fontSize,
    })}
`;

const LeadText = styled.p`
    font-size: 20px;
    width: 70%;
    margin: auto;
    margin-bottom: 20px;

    @media(max-width: 990px) {
        width: 100%;
    }

    ${(props) => ({
        'font-size': props.fontSize,
    })}
`;

export {
    Offer,
    Pointer,
    HeroText,
    LeadText,
};
