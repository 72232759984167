import PropTypes from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import get from 'lodash/get';
import styled from 'styled-components';
import queryString from 'query-string';
import { callFunc } from 'utils';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-weight: 500;
    color: #5d5d5d;
    border-top: 1px solid #eee;
    padding: 20px 0 0 0;
`;

function Paginator(props) {
    const { handlers, query } = props;
    // get the params from the url
    const queryParams = queryString.parse(query);
    const handleOnInventoryChange = get(handlers, 'handleOnInventoryChange');
    const defaultLimit = get(props, 'schema.rowCount.default', 25);
    const totalRecords = get(props, 'totalRecords');
    const pageLimit = queryParams.limit || defaultLimit;
    const page = queryParams.page || 1;
    // `pageCount` Required. The total number of pages.
    const pageCount = Math.ceil(totalRecords / pageLimit);
    // return if pageCount is zero
    if (!pageCount) {
        return null;
    }
    // to keep the current page selected, we need to subtract one
    // because `ReactPaginate` starts its pagination from 0.
    // since we handle the pagination starting from 1 so it doesn't
    // look weird the params (page=0)
    const currentPage = (parseInt(page, 10) - 1);

    function handlePageClick(value) {
        // `react-paginate` page value starts at 0
        // need to add 1
        const pageNumber = value.selected + 1;
        if (typeof handleOnInventoryChange !== 'function') {
            throw Error('DataTable.Paginator expects a function called `handleOnInventoryChange`');
        }
        callFunc(handleOnInventoryChange, { page: pageNumber });
    }

    return (
        <Wrapper>
            Total records {totalRecords}
            <ReactPaginate
                previousLabel="previous"
                nextLabel="next"
                breakLabel="..."
                breakClassName="break-me"
                initialPage={currentPage}
                forcePage={currentPage}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination pagination-sm justify-content-end"
                subContainerClassName="pages pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
            />
        </Wrapper>
    );
}

Paginator.propTypes = {
    handlers: PropTypes.object.isRequired
};

export default Paginator;
