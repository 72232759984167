import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Item } from 'components';
import { Box, Typography } from 'lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const UserLogo = styled.div`
    text-align: center;
    font-size: 24px;
    padding-top: 10px;
    color: #ababab;
`;

const ItemsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

// sample

function Profile(props) {
    const [state] = useState({
        user: {
            username: 'normandoors',
            city    : 'Long Beach, CA'
        },
        items: [{}, {}, {}, {}, {}, {}]
    });

    const { user, items } = state;

    function getActions(item) {
        const { history } = props;
        const { id, title, category } = item;

        return {
            onViewDetails: () => {
                // make the title read with dashes
                const name = title.replace(/\s/g, '-').toLowerCase();
                const cat = category.replace(/\s/g, '-').toLowerCase();
                // navigate to details
                history.push(`/details/${cat}/${name}/${id}`);
            }
        };
    }

    return (
        <Container>
            <Box flexDirection="column" background="#fff" border="1px solid #eee" marginTop="10px">
                <UserLogo>
                    <FontAwesomeIcon icon={faUser} />
                </UserLogo>
                <Typography
                    type="h2"
                    color="#675a5a"
                    margin="10px 0"
                    width="100%"
                    fontSize="1.2rem"
                    textTransform="uppercase"
                    textAlign="center"
                >
                    {user.username}
                </Typography>
                <Typography textAlign="center">
                    {user.city}
                </Typography>
            </Box>
            <Box background="#fff" border="1px solid #eee" marginTop="10px" padding="10px">
                <ItemsWrapper>
                    {items.map((item) => (
                        <Item
                            key={item.id}
                            className="card-item"
                            data={item}
                            actions={getActions(item)}
                        />
                    ))}
                </ItemsWrapper>
            </Box>
        </Container>
    );
}

export default Profile;
