import React from 'react';
import { Route } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Recover from './Recover';
import Verify from './Verify';

function Auth() {
    return (
        <div>
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/register" component={Register} />
            <Route exact path="/auth/recover" component={Recover} />
            <Route exact path="/auth/verify" component={Verify} />
        </div>
    );
}

export default Auth;
