import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAP_ACCESS_KEY } from 'config/global';
import { MONGO_DB_APP_ID } from 'constants/app'
import { analytics } from 'config/firebase';
import get from 'lodash.get';
import * as Realm from "realm-web";

function callFunc(callback, ...args) {
    return typeof callback === 'function' ? callback(...args) : null;
}

/**
 * Build the url for looking at the details
 *
 * @param {string} title The name of the item for sale
 * @param {string} category The category of the item
 * @param {integer} id The id of the item
 */
function buildDetailsUrl(title, category, id) {
    const name = title.replace(/\s/g, '-').toLowerCase();
    const cat = category.replace(/\s/g, '-').toLowerCase();
    return `/details/${cat}/${name}/${id}`;
}

function parseStripeDate(timestamp) {
    const d = new Date(timestamp * 1000);
    return d.toDateString();
}

async function initData(authUser, dispatch) {
    try {
        if (authUser) {
            // fetch a token
            const accessToken = await authUser.getIdToken();
            // login to realm with the token
            const app = new Realm.App({ id: MONGO_DB_APP_ID });
            const crendentials = Realm.Credentials.jwt(accessToken);
            const realmUser = await app.logIn(crendentials);
            // create user data
            const {
                email, emailVerified, photoUrl, phoneNumber, uid, providerData
            } = authUser;
            const provider = providerData[0];
            const respBuss = await realmUser.functions.getListings('business');
            const contactResp = await realmUser.functions.getOwnerInfo();
            const business = respBuss.result.length > 0 ? respBuss.result[0] : null;
            const contactInfo = contactResp.result;
            const subscription = {};

            const user = {
                accessToken,
                email,
                emailVerified,
                photoUrl,
                phoneNumber,
                uid,
                provider,
            };

            user.displayName = get(authUser, 'displayName', null);

            dispatch({
                type: 'GET_APPLICATION_DATA_SUCCESS',
                data: {
                    user,
                    business,
                    contactInfo,
                    subscription,
                    realmUser,
                }
            });
        } else {
            dispatch({
                type: 'GET_APPLICATION_DATA_SUCCESS',
                data: {
                    user        : null,
                    business    : null,
                    subscription: null
                }
            });
        }
    } catch (err) {
        // FIXME: add logging
    }
}

async function loginWithCustomJwt(jwt) {
    const app = new Realm.App({ id: MONGO_DB_APP_ID });
    const crendentials = Realm.Credentials.jwt(jwt);
    const user = await app.logIn(crendentials);
    return user;
}

/**
 * Parse addresses
 *
 * @param {String} address City or address
 * @param {Object} geoPoints object returned when the user has given us permission to use their location
 * @returns A geo point or address object
 */
async function getGeoloc(address, geoPoints) {
    let geocoder;
    try {
        const loader = new Loader({
            apiKey : GOOGLE_MAP_ACCESS_KEY,
            version: 'weekly'
        });
        await loader.load();
        // eslint-disable-next-line no-undef
        geocoder = new google.maps.Geocoder();

        let result;
        if (geoPoints) {
            try {
                await geocoder.geocode({ location: geoPoints }, (response) => {
                    // eslint-disable-next-line no-undef
                    if (response && response[0]) {
                        // eslint-disable-next-line prefer-destructuring
                        result = response[0];
                    }
                });
                return result;
            } catch (err) {
                return 'error-decoding-geopoints';
            }
        }

        // eslint-disable-next-line consistent-return
        await geocoder.geocode({ address }, (results, status) => {
            // eslint-disable-next-line no-undef
            if (status === google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                result = { lat, lng };
            }
        });

        return result;
    } catch (error) {
        throw new Error(JSON.stringify({
            message: 'Error loading or parsing the address to geoloc',
            error
        }));
    }
}

function getGoogleLoader() {
    return new Loader({
        apiKey : GOOGLE_MAP_ACCESS_KEY,
        version: 'weekly'
    });
}

/**
 * Parse the address and return the geoloc
 *
 * @param {String} address The address to geoloc. The format should be: street, city, state zip
 * @returns A geoloc object
 */
async function convertAddressToGeoPoints(address) {
    let geocoder;
    // validate the address
    if (!address) {
        Promise.reject(new Error('No address provided'));
        throw new Error(JSON.stringify({
            message: 'Unable to geoloc the address',
            error  : 'No address provided'
        }));
    }
    try {
        // get the google loader
        const loader = getGoogleLoader();
        // load the google maps api
        await loader.load();
        // eslint-disable-next-line no-undef
        geocoder = new google.maps.Geocoder();
        // store the result
        let result;
        // eslint-disable-next-line consistent-return
        await geocoder.geocode({ address }, (results, status) => {
            // eslint-disable-next-line no-undef
            if (status === google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                result = { lat, lng };
            }
        });
        // return the geoloc
        return result;

    } catch (error) {
        Promise.reject(new Error('Unable to convert the address to geoloc'));
        throw new Error(JSON.stringify({
            message: 'Error loading or parsing the address to geoloc',
            error
        }));
    }
}

// create a function that converts a geo point to an address
async function convertGeoPointsToAddress(geoPoints) {
    let geocoder;
    try {
        const loader = getGoogleLoader();
        await loader.load();
        // eslint-disable-next-line no-undef
        geocoder = new google.maps.Geocoder();
        // store the result
        let result;
        // eslint-disable-next-line consistent-return
        await geocoder.geocode({ location: geoPoints }, (response) => {
            // eslint-disable-next-line no-undef
            if (response && response[0]) {
                // eslint-disable-next-line prefer-destructuring
                result = response[0];
            }
        });
        return result;
    } catch (error) {
        throw new Error(JSON.stringify({
            message: 'Error loading or parsing the geoPoints to address',
            error
        }));
    }
}

function getUserLocationFromStorage() {
    const userLocation = localStorage.getItem('343UserLocation');
    if (userLocation) {
        return JSON.parse(userLocation);
    }
    return null;
}

function getMostResentUserLocation() {
    const useDefaultLocation = localStorage.getItem('useDefaultLocation');
    if (useDefaultLocation) {
        return null;
    }

    if (navigator.geolocation) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { coords } = position;
                    const userLocation = JSON.stringify({
                        lat: coords.latitude,
                        lng: coords.longitude,
                        status: 'granted'
                    });
                    window.localStorage.setItem('343UserLocation', userLocation);
                    return userLocation;
                });
            }

            if (result.state === 'denied') {
                window.localStorage.removeItem('343UserLocation');
                return null;
            }
        });
    } else {
        return null;
    }
}

/**
 * Track events to google analytics
 *
 * @param {*} event The name of the event to track such as page_view, add_to_cart, etc
 * @param {Object} eventProperties The properties to track with the event. The object can contain any number of properties.
 */
function trackEvent(event = 'page_view', eventProperties = {}) {
    if (analytics && typeof analytics.logEvent === 'function' && window.location.host === '343trucking.com') {
        analytics.logEvent(event, eventProperties);
    }
}

export {
    callFunc,
    convertAddressToGeoPoints,
    convertGeoPointsToAddress,
    buildDetailsUrl,
    parseStripeDate,
    initData,
    loginWithCustomJwt,
    getGeoloc,
    getUserLocationFromStorage,
    getMostResentUserLocation,
    trackEvent
};
