const CATEGORIES = {
    all         : 'Most Recent',
    autos       : 'Autos',
    general     : 'General',
    jobs        : 'Trabajos',
    apartments  : 'Apartamentos',
    trucking    : 'Trucking',
    others      : 'Otras Categorias',
    services    : 'Servicios',
    'auto-parts': 'Auto Partes'
};

const VALID_CATEGORIES = [
    'truck-dealers',
    'repair-shops',
    'mobile-services',
    'truck-stops',
    'truck-wash',
    'truck-parts',
    'for-sale',
    'trailer-sales',
    'equipment-sales',
    'insurance',
    'towing',
    'general'
];

const VALID_CATEGORIES_LABELS = {
    'truck-dealers'  : 'Truck Dealers',
    'for-sale'       : 'Truck Sales',
    'repair-shops'   : 'Repair Shops',
    'mobile-services': 'Mobile Services',
    'truck-stops'    : 'Truck Stops',
    'truck-wash'     : 'Truck Wash',
    'truck-rental'   : 'Truck Rental',
    'truck-parts'    : 'Truck Parts',
    'truck-jobs'     : 'Jobs',
    'towing-services': 'Towing Services',
    services         : 'Services',
    general          : 'General'
};

const SUB_CATEGORIES = {
    welding  : 'Welding',
    insurance: 'Insurance'
};

const CONDITIONS = {
    'like-new': 'Como Nuevo',
    new       : 'Nuevo',
    used      : 'Usado'
};

export {
    VALID_CATEGORIES_LABELS,
    CATEGORIES,
    SUB_CATEGORIES,
    CONDITIONS,
    VALID_CATEGORIES
};
