import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import firebase from 'config/firebase';

const VerifyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 4px;
    flex-direction: column;
    padding: 25px 20px;
    margin: 65px 10px 10px 10px;
    background: #fff;
`;

function Verify(props) {
    const [resendEmail, setResendEmail] = useState(false);

    useEffect(() => {
        async function sendUserEmailVerification() {
            try {
                const user = firebase.auth().currentUser;
                const emailVerificationSent = window.localStorage.getItem('emailVerificationSent');
                if (user && !emailVerificationSent) {
                    await user.sendEmailVerification();
                    // set in local storage a variable that indicates that the email has been sent
                    window.localStorage.setItem('emailVerificationSent', true);
                }
            } catch (err) {
                console.log('ERROR Sending code', err);
            }
        }
        sendUserEmailVerification();
    }, []);

    function navigateToCreateAd() {
        const { history } = props;
        // get the search param onboarding, plan, and category and navigate to admin/inventory/add page
        const queryParams = new URLSearchParams(history.location.search);
        const onboarding = queryParams.get('onboarding');
        const plan = queryParams.get('plan');
        const category = queryParams.get('category');
        if (onboarding === 'true') {
            window.localStorage.removeItem('emailVerificationSent');
            history.push(`/admin/inventory/add?onboarding=true&plan=${plan}&category=${category}`);
            return;
        }
        history.push('/admin/choose-category');
    }

    function sendVerificationEmail() {
        const user = firebase.auth().currentUser;
        setResendEmail(true);
        user.sendEmailVerification()
            .then(() => {
                setResendEmail(false);
                // set in local storage a variable that indicates that the email has been sent
                window.localStorage.setItem('emailVerificationSent', true);
            })
            .catch((error) => {
                console.log('ERROR Sending code', error);
                setResendEmail(false);
            });
    }

    return (
        <Container>
            <VerifyWrapper>
                <Content>
                    <Alert color="default">
                        <div className="alert alert-success">
                            <FontAwesomeIcon icon={faCheck} style={{ fontSize: '18px', color: 'green' }} />
                            {' '}
                            Account created!
                        </div>
                        <p>
                            We sent you an email to verify your account. Check your spam folder if you do not see it.
                            <br />
                            Once you verify your account, proceed to create your listing.
                        </p>
                        {!resendEmail ? (
                                <p>I did not get an email to verify. <button className="btn btn-link p-0" onClick={sendVerificationEmail}>Resend email</button></p>
                            ) : (
                                <p>Email sent. Check again!</p>
                            )}
                        <p><strong>Ready to advertise?</strong></p>
                        <button className="btn btn-danger" onClick={navigateToCreateAd}>
                            Create
                        </button>
                    </Alert>
                </Content>
            </VerifyWrapper>
        </Container>
    );
}

export default Verify;
