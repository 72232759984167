import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import queryString from 'query-string';
import get from 'lodash.get';
import { Footer } from 'components';
import {
    Container, Jumbotron
} from 'reactstrap';
import { API_URL } from 'config/global';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Typography } from 'lib';
import Axios from 'apis/interceptor';

const HeroText = styled.h1`
    font-family: AvenirLTStd-Black,tahoma,verdana,arial,sans-serif !important;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
`;

// FIXME: when getting the subscription details, please update the entire app.
function Success(props) {
    const [subscription, setSubscription] = useState({ isLoading: true });
    const { history } = props;
    const queryParams = queryString.parse(
        get(history, 'location.search', '')
    );
    useEffect(() => {
        async function getStripeSessionDetails() {
            try {
                const { data } = await Axios({
                    method: 'get',
                    url   : `${API_URL}/api/billing/checkout-session?session_id=${queryParams.session_id}`
                });
                // update the state of the component
                setSubscription((prevState) => ({ ...prevState, customer: data.customer, isLoading: false }));
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log('error getting stripe session information', err);
            }
        }
        if (queryParams.session_id) {
            getStripeSessionDetails();
        } else {
            setSubscription((prevState) => ({ ...prevState, isLoading: false }));
        }
    }, [queryParams.session_id]);

    if (subscription.isLoading) {
        return (
            <>
                <Container>
                    <Jumbotron className="bg-transparent text-center mb-0">
                        Loading...
                    </Jumbotron>
                </Container>
            </>
        );
    }

    return (
        <>
            <Container style={{ height: 'calc(100vh - 142px)' }}>
                <Jumbotron className="bg-transparent text-center mb-0">
                    <HeroText>
                        <FontAwesomeIcon icon={faCheck} className="text-success" /> Thank you{(subscription.customer && subscription.customer.name !== null) && ` ${subscription.customer.name}`}!
                    </HeroText>
                    <Typography fontSize="18px">
                        Your subscription was created succesfully.
                        You can now create your ad to start reaching customers.
                    </Typography>
                    <Link
                        to="/admin/business/create"
                        className="btn btn-success p-3 pl-5 pr-5"
                    >
                        Create Ad Now <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    <Link
                        to="/admin/account"
                        className="btn btn-default p-3 pl-5 pr-5"
                    >
                        My Account <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                </Jumbotron>
            </Container>

            <Footer />
        </>
    );
}

Success.propTypes = {
    history: PropTypes.object
};

Success.defaultProps = {
    history: { push: () => { } }
};

export default Success;
