/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash.get';
import {
    Button,
    Container,
} from 'reactstrap';
import { APP_LANG, DEFAULT_LOCATION, DEFAULT_LIMIT } from 'constants/app';
import InfiniteScroll from 'react-infinite-scroll-component';
import Axios from 'apis/http';
import HomeSearch from 'components/HomeSearch';
import Footer from 'components/Footer';
import Masonry from 'react-masonry-css';
import SETTINGS from 'constants/languages.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from 'AppContext';
import NewItem from 'components/Item';
import { isMobile } from 'react-device-detect';
import { mapPropsFromMongoDb, navigateToDetails } from './helperFuncs';
import ChangeLocation from 'components/Modals/ChangeLocation/ChangeLocation';
import { getUserLocationFromStorage, trackEvent } from 'utils';

const CardsWrapper = styled.div``;
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

function Home(props) {
    const { history } = props;
    const [appState, dispatch] = React.useContext(AppContext);
    const { currentPage } = appState.items;
    const [hasMore, setHasMore] = useState(true);
    const [openChangeLocationModal, setOpenChangeLocationModal] = useState(false);
    const userLocation = getUserLocationFromStorage();
    const lat = userLocation ? userLocation.lat : DEFAULT_LOCATION.lat;
    const lng = userLocation ? userLocation.lng : DEFAULT_LOCATION.lng;
    // we use this to determine if we need to display the distance on the card.
    const displayMillage = get(userLocation, 'status') === 'granted';

    useEffect(() => {
        trackEvent('page_view', {
            page_title   : 'Home',
            page_path    : '/home',
            page_location: window.location.href
        });
    }, []);

    useEffect(() => {
        function loadData() {
            // eslint-disable-next-line no-use-before-define
            getLatestItemsForSale();
        }
        // we only want to fetch data if we don't have any items
        // and if we are not fetching data which happens when we are
        // fetching data for the first time.
        if (appState.items.classifieds.length === 0 && appState.status === 'FETCHING_DONE') {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.status]);

    async function getLatestItemsForSale() {
        const baseUrl = 'https://us-west-2.aws.data.mongodb-api.com/app/truckingapplication-pzzgx/endpoint';
        try {
            const resp = await Axios({
                method: 'get',
                url   : `${baseUrl}/search_loc_or_category?lng=${lng}&lat=${lat}&page=${currentPage}&limit=${DEFAULT_LIMIT}`
            });
            // get the items
            const classifieds = get(resp, 'data.results');
            const totalRecords = get(resp, 'data.totalCount', 0);
            const currPage = appState.items.currentPage + 1;
            // we don't want to fetch for more items if we have matched the totalRecords.
            if (totalRecords === appState.items.classifieds.length) {
                setHasMore(false);
            }
            dispatch({ type: 'GET_ITEMS_SUCCESS', data: { classifieds, totalRecords, currentPage: currPage } });
        } catch (err) {
            // toast.error('Unable to fetch data at this time!');
        }
    }

    function getEndMesasge() {
        return (
            <p className="text-muted text-center">
                {SETTINGS[APP_LANG].NO_MORE_DATA}
            </p>
        );
    }

    function getLoader() {
        return <div className="text-center text-muted">{SETTINGS[APP_LANG].LOADING}</div>;
    }

    function handleSearch(querySearch) {
        history.push({
            pathname: '/search',
            search  : querySearch
        });
    }

    function getActions(item) {
        const { _id, category } = item || {};
        return {
            onViewDetails: (e) => navigateToDetails(_id, category, history, isMobile, e.target.name)
        };
    }

    return (
        <div>
            <HomeSearch placeholder="Search" {...{ handlers: { handleSearch } }} />
            <Container>
                <SectionHeader className="pt-4 pb-4">
                    <div>
                        <div className="d-flex align-items-center">
                            <div
                                id="location"
                                className="text-primary"
                                style={{ borderBottom: '1px dotted', cursor: 'pointer' }}
                                role="button"
                                onClick={() => setOpenChangeLocationModal(true)}
                            >
                                Los Angeles &amp; {isMobile && <br />} Surrounding Areas
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button color="link" onClick={() => history.push('/categories')}>
                            Categories <FontAwesomeIcon icon={faChevronRight} />
                        </Button>
                    </div>
                </SectionHeader>
                <CardsWrapper>
                    <InfiniteScroll
                        dataLength={appState.items.classifieds.length}
                        next={getLatestItemsForSale}
                        loader={getLoader()}
                        endMessage={getEndMesasge()}
                        hasMore={hasMore}
                    >
                        <Masonry
                            breakpointCols={{
                                default: 3,
                                1100   : 3,
                                992    : 2,
                                700    : 2,
                                500    : 1
                            }}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {Array.isArray(appState.items.classifieds) && appState.items.classifieds.map((item) => (
                                <NewItem
                                    key={item?._id}
                                    data={mapPropsFromMongoDb(item)}
                                    actions={getActions(item)}
                                    className="card-item mb-3"
                                    displayMillage={displayMillage}
                                />
                            ))}
                        </Masonry>
                    </InfiniteScroll>
                    {(userLocation?.status === 'granted' && appState.items.classifieds.length === 0 && appState.status === 'FETCHING_ITEMS_DONE') && (
                        <p className="text-muted text-center">
                            <button
                                className="btn btn-dark mt-2 pl-4 pr-4"
                                onClick={() => {
                                    localStorage.removeItem('343UserLocation');
                                    localStorage.setItem('useDefaultLocation', true);
                                    window.location.reload();
                                }}
                            >
                                OK
                            </button>
                        </p>
                    )}
                </CardsWrapper>
            </Container>
            <Footer />
            <ChangeLocation
                isOpen={openChangeLocationModal}
                data={appState.address}
                toggle={() => setOpenChangeLocationModal(!openChangeLocationModal)}
                onComplete={() => window.location.reload()}
            />
        </div>
    );
}

Home.propTypes = {
    history: PropTypes.object.isRequired
};

export default Home;
