import styled from 'styled-components';

const TableHeadStyled = styled.thead``;
const TableHead = styled.th`
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border-top: none !important;
    color: #595d6e;
    font-weight: 500;
    font-size: 14px;
`;
const TableRow = styled.tr``;
const TableBodyStyled = styled.tbody``;
const TableCell = styled.td`
    vertical-align: middle !important;
`;

const TableCellImageStyled = styled.img`
    width: 100px;
    ${({ styles }) => {
        return {
            ...styles
        };
    }}
`;

export {
    TableHeadStyled,
    TableRow,
    TableHead,
    TableBodyStyled,
    TableCell,
    TableCellImageStyled
};
