import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import get from 'lodash.get';
import AdminContext from 'pages/Admin/Context';
import { Accordion, Divider } from 'lib';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { callFunc } from 'utils';
import queryString from 'query-string';

const FiltersStyled = styled.div`
    background: #fff;
    border: 1px solid #eee;
    padding: 10px;
`;

const NavLinkStyled = styled(NavLink)`
    display: block;
    padding: 5px 20px;
    margin-bottom: 3px;
    color: #333;
    font-weight: 500;

    &:hover {
        background: #f5f5f5;
        border-radius: 6px;
    }
    ${({ padding, color, fontSize }) => ({ padding, color, fontSize })}
`;

function Filters(props) {
    const queryParams = queryString.parse(window.location.search);
    const [state, setState] = useState({
        expandedId: `accordion__${queryParams.brand}`
    });
    const context = useContext(AdminContext);
    const { businessInfo } = context;
    const { handlers } = props;
    const categories = JSON.parse(get(businessInfo, 'categories', null));
    // const categories = filters;
    const handleOnInventoryChange = get(props, 'handlers.handleOnInventoryChange', () => { });

    function handleOnClick(params) {
        handleOnInventoryChange({ ...params, page: 1 });
    }

    function isSelected(brand = '', model = '') {
        if (queryParams.brand === brand && queryParams.model === model) {
            return 'filter-selected';
        }
        return null;
    }

    function handleOnClearFilters() {
        const { resetFilters } = handlers;
        setState({ expandedId: '' });
        callFunc(resetFilters());
    }

    function onAccordionClick(brand = '') {
        let expandedId = `accordion__${brand}`;
        // if they are the same, we want to close the current opened accordion
        if (state.expandedId === expandedId) {
            expandedId = `accordion__${brand}__close`;
        }
        setState({ expandedId });
    }

    function buildAccordionContent(brand, models) {
        if (Array.isArray(models)) {
            return (
                <Nav vertical>
                    {
                        models.map((autoModel) => {
                            const key = `${brand}__${autoModel}`;
                            return (
                                <NavItem key={key}>
                                    <NavLinkStyled
                                        className={isSelected(brand, autoModel)}
                                        brand={brand}
                                        model={autoModel}
                                        color="link"
                                        onClick={() => handleOnClick({
                                            brand,
                                            model: autoModel
                                        })}
                                    >
                                        {autoModel}
                                    </NavLinkStyled>
                                </NavItem>
                            );
                        })
                    }
                </Nav>
            );
        }
        return null;
    }

    return (
        <FiltersStyled>
            <NavLinkStyled
                padding="5px"
                color="#0072ec !important"
                fontSize="12px"
                onClick={handleOnClearFilters}
            >
                View All Parts
            </NavLinkStyled>
            <Divider />
            {Array.isArray(categories)
                && categories.map((category) => {
                    const { brand, models } = category;
                    const accordionId = `accordion__${brand}`;
                    return (
                        <Accordion
                            key={accordionId}
                            id={accordionId}
                            collapse={accordionId === state.expandedId}
                            title={brand}
                            onClick={() => onAccordionClick(brand)}
                            content={buildAccordionContent(brand, models)}
                        />
                    );
                })}
        </FiltersStyled>
    );
}

Filters.propTypes = {
    handlers: PropTypes.object
};

Filters.defaultProps = {
    handlers: {}
};

export default Filters;
