/**
 * App name
 */
const APP_NAME = '343Trucking';

/**
 * App language
 */
const APP_LANG = 'en';

/**
 * App logo
 */
const APP_LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/trucking-9c0bd.appspot.com/o/manifest-icon-512.maskable.png?alt=media&token=47588b51-a238-4aea-a5ac-52b4332968c1';

const S3_BUCKET = '';

const GOOGLE_API_KEY = 'AIzaSyA3rGKyaY9PEqlGqoeg9FErlIwv2KDHSsM';

const MONGO_DB_APP_ID = 'truckingapplication-pzzgx';

const STRIPE_PK_KEY = '';

const PLANS = {
    FREE: {
        PLAN_ID: 'free'
    },
    BASIC: {
        SUBSCRIPTION: {
            PLAN_ID: 'price_1IP0QFEYPtx9NYO6ZhnP4ENg',
        },
        PRICING_DETAILS: {
            STRIPE_PRICE_ID: '',
            FEATURES: [
                'Upload up to 4 photo',
                'Add a youtube video',
                'Listing is live for 2 weeks.',
                'Relist for another 2 weeks if it does not sell.'
            ],
            PHOTOS_ALLOWED: 4,
            VIDEOS_ALLOWED: 1,
            PRICE: '$15',
            PRICE_TAG: 'One time fee'
        }
    },
    STANDARD: {
        SUBSCRIPTION: {
            PLAN_ID: 'price_1LeYlDEYPtx9NYO6yAwrOiYm',
            NUM_ALLOWED: 10,
        },
        PRICING_DETAILS: {
            STRIPE_PRICE_ID: '',
            FEATURES   : [
                'Upload up to 20 photos',
                'Add a youtube video',
                'Listing is live for 8 weeks.',
                'Relist for another 4 weeks if it does not sell.'
            ],
            PHOTOS_ALLOWED: 20,
            VIDEOS_ALLOWED: 1,
            PRICE: '$29',
            PRICE_TAG: 'One time fee'
        }
    },
    PREMIUM: {
        SUBSCRIPTION: {
            PLAN_ID: 'price_1LeYlREYPtx9NYO6F6ezYDji',
            NUM_ALLOWED: 25,
        },
        PRICING_DETAILS: {
            STRIPE_PRICE_ID: '',
            FEATURES   : [
                'Upload up to 30 photos.',
                'Listing is live until it sells.',
                'Add a youtube video.',
                'Featured on the homepage.',
                'Premium badge on your listing.',
                'Highlighted in search results.',
            ],
            PHOTOS_ALLOWED: 30,
            VIDEOS_ALLOWED: 1,
            PRICE: '$49',
            PRICE_TAG: 'One time fee'
        }
    }
};

const DEFAULT_LOCATION = {
    lng: -118.193741, // -119.11642;
    lat: 33.770050    // 35.290683;
}
const DEFAULT_LIMIT = 25;
const NUMBER_OF_FREE_MONTHS = 2;

export {
    APP_NAME,
    APP_LANG,
    APP_LOGO_URL,
    S3_BUCKET,
    PLANS,
    GOOGLE_API_KEY,
    STRIPE_PK_KEY,
    DEFAULT_LOCATION,
    DEFAULT_LIMIT,
    MONGO_DB_APP_ID,
    NUMBER_OF_FREE_MONTHS,
};
