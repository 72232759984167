function mapPropsFromMongoDb(item = {}) {
    const {
        _id, type, name, city, state, contact = {},
        businessHours, logoUrl, services, calcDistance, listingDetails,
        category, subCategory, isSponsored, images, mileage, price, offer, status,
    } = item;
    let location = `${city}, ${state}`;
    if (item.category === 'for-sale' && item.business) {
        location = `${item.business.city}, ${item.business.state}`;
    }

    const businessContact = item?.business?.contact || {};

    return {
        id               : _id,
        title            : name,
        descriptionTrimed: services || listingDetails?.description,
        distance         : calcDistance ? (calcDistance / 1609.344).toFixed(2) : null,
        location,
        type,
        category,
        subCategory,
        businessHours,
        logoUrl,
        listingDetails,
        isSponsored,
        images,
        mileage,
        price,
        offer,
        status,
        ...contact,
        ...businessContact,
    };
}

function navigateToDetails(_id, category, history, isMobile, elName) {
    if (['email', 'primaryPhone'].includes(elName)) {
        return;
    }
    const cat = category.replace(/\s/g, '-').toLowerCase();
    if (isMobile) {
        window.open(`/details/${cat}/${_id}`, '_blank');
    } else {
        history.push(`/details/${cat}/${_id}`);
    }
}

export {
    mapPropsFromMongoDb,
    navigateToDetails
};
