import styled from 'styled-components';

const AppContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Main = styled.main`
    width: 100%;
    position: absolute;
    margin-top: 70px;
`;

export {
    AppContent,
    Main
};
