import isEmpty from 'lodash/isEmpty';
import { S3_BUCKET } from 'constants/app';
import { CLOUD_FRONT_URL, NO_IMG_URL } from 'constants/urls';
// TODO: fix the parsing of this images
// let's not do this. The problem with this approac is
// that we are returning single images and arrays.
// so parsing will fail.
export function parseImages(images) {
    try {
        if (images === 'no-image') {
            return NO_IMG_URL;
        }
        const imgs = JSON.parse(images);
        if (Array.isArray(imgs)) {
            return imgs[0];
        }
        // eslint-disable-next-line no-empty
    } catch (error) {
        console.log(error);
    }
    return null;
}

export function renderImage(imageS3Url, size) {
    if (!imageS3Url) {
        return null;
    }
    const arr = imageS3Url.split('/');
    const imgName = arr[arr.length - 1];

    const imageRequest = JSON.stringify({
        bucket: S3_BUCKET,
        key   : imgName,
        edits : {
            resize: { width: size, fit: 'cover', quality: 100 }
        }
    });

    return `${CLOUD_FRONT_URL}/${btoa(imageRequest)}`;
}

export function renderImageFromCloudFront(imageS3Url, size = 50) {
    if (isEmpty(imageS3Url) || imageS3Url === 'no-image') {
        return NO_IMG_URL;
    }

    const arr = imageS3Url.split('/');
    const imgName = arr[arr.length - 1];

    const imageRequest = JSON.stringify({
        bucket: S3_BUCKET,
        key   : imgName,
        edits : {
            resize: { width: size, fit: 'cover' }
        }
    });

    return `${CLOUD_FRONT_URL}/${btoa(imageRequest)}`;
}
