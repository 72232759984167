import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Component = styled.div`
    ${({
        as, children, textAlign, fontSize, width, margin, ...rest
    }) => ({
        textAlign,
        fontSize,
        width,
        margin,
        ...rest
    })}
`;

function Typography(props) {
    const {
        children, className, type, ...rest
    } = props;
    return (
        <Component as={type} {...rest}>
            {children}
        </Component>
    );
}

Typography.propTypes = {
    children: PropTypes.any,
    type    : PropTypes.oneOf(['a', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
};

Typography.defaultProps = {
    children: null,
    type    : 'p'
};

export default Typography;
