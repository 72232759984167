import React, { useState } from 'react';
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faEllipsisV, faEye, faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import get from 'lodash.get';
import styled from 'styled-components';
import { callFunc } from 'utils/helpers';
import BRANDING from 'constants/branding';

const Label = styled.div`
    width: 70%;
    padding-left: 10px;
`;

const DropdownToggleStyled = styled(DropdownToggle)`
    background-color: inherit;
    border-radius: 4px;
    border: 1px solid #fff;
    color: #83869c;
    width: 40px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    :hover {
        border-radius: 3px !important;
        background: #fbfbfb;
        color: #909090;
        border: 1px solid #eee;
    }
    :focus {
        outline: none;
        border: 1px solid #eee;
        box-shadow: none;
    }
`;

const DropdownItemStyled = styled(DropdownItem)`
    display: flex;
    align-items: center;
    color: #585858;
    transition: all 0.3s;
    :hover {
        cursor: pointer;
        color: ${(props) => BRANDING.COLORS[props.color] || '#585858'};
    }
    ${(props) => {
        const color = get(props, 'color');
        return {
            color: BRANDING.COLORS[color]
        };
    }}
`;

function TableActions(props) {
    const [isOpen, setIsOpen] = useState(false);
    // eslint-disable-next-line react/prop-types
    const { schema, row } = props;
    // the actions for the row menu
    const tableActions = get(schema, 'table.actions', []);
    // get handlers
    const handlers = get(props, 'handlers');

    function toggle() {
        setIsOpen(!isOpen);
    }

    return (
        <ButtonDropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggleStyled>
                <FontAwesomeIcon icon={faEllipsisV} />
            </DropdownToggleStyled>
            <DropdownMenu key={`menu__${row.id}`}>
                {tableActions.map((action) => {
                    let icon;
                    switch (action.icon) {
                    case 'eye':
                        icon = faEye;
                        break;
                    case 'edit':
                        icon = faEdit;
                        break;
                    case 'trash-alt':
                        icon = faTrashAlt;
                        break;
                    default:
                        break;
                    }
                    function handleClick() {
                        callFunc(handlers[action.onClick], row);
                    }
                    return (
                        <DropdownItemStyled
                            key={`${action.id}__${row.id}`}
                            onClick={handleClick}
                            color={action.color}
                        >
                            <FontAwesomeIcon icon={icon} />
                            <Label>{action.label}</Label>
                        </DropdownItemStyled>
                    );
                })}
            </DropdownMenu>
        </ButtonDropdown>
    );
}

export default TableActions;
