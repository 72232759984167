/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { renderImageFromCloudFront } from 'utils/image';
import { TableCellImageStyled } from '../../styled';

function TableCellImage(props) {
    // eslint-disable-next-line react/prop-types
    const {
        row,
        column: {
            key, className, styles, placeholderImage
        }
    } = props;
    // get the source image
    const src = row[key] || placeholderImage;
    return (
        <TableCellImageStyled
            src={renderImageFromCloudFront(src)}
            className={className}
            styles={styles}
        />
    );
}

TableCellImage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    row   : PropTypes.object,
    column: PropTypes.shape({
        key      : PropTypes.string,
        className: PropTypes.string,
        styles   : PropTypes.object
    })
};

TableCellImage.defaultProps = {
    row   : {},
    column: {
        key      : '',
        className: 'rounded',
        styles   : {}
    }
};

export default TableCellImage;
