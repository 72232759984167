import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    Button, Col, Row
} from 'reactstrap';
import get from 'lodash.get';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';

const ToolbarWrapper = styled.div`
    width: 100%;
`;

const SearchBoxWrapper = styled.div`
    display: flex;
`;

const Label = styled.div`
    margin-right: 10px;
`;

const SearchBox = styled.input`
    padding: .375rem 0.375rem 0.375rem 2.75rem;
    ::placeholder {
        color: #6c757d;
        font-size: 14px;
    }
`;

const ButtonStyled = styled(Button)`
    position: absolute;
    padding: 6px 15px;
    color: #545454;

    :hover: {
        cursor: pointer;
        border: 1px solid #eee;
    }
`;

function TableToolbar(props) {
    const searchRef = React.useRef(null);
    const search = get(props, 'history.location.search');
    const queryParams = queryString.parse(search);
    const schema = get(props, 'schema');
    const handlers = get(props, 'handlers');
    const handleOnInventoryChange = get(handlers, 'handleOnInventoryChange');
    // get the per page limit options
    const rowCount = get(schema, 'rowCount', {});
    const sortBy = get(schema, 'sortBy', {});
    const searchSchema = get(schema, 'search', {});
    // page default count
    const defaultCount = queryParams.limit || rowCount.default;
    // build the options
    const countOptions = Array.isArray(rowCount.options) ? rowCount.options.map((option) => ({
        value: option,
        label: option
    })) : [];
    const selectStyles = {
        container: (provided) => ({
            ...provided,
            width: 80
        })
    };

    function onSubmitSearch() {
        const { value } = searchRef.current;
        handleOnInventoryChange({
            limit : queryParams.limit,
            page  : 1,
            search: value
        });
    }

    function onKeyUp(e) {
        if (e.target.value.length <= 0) {
            handleOnInventoryChange({
                limit : queryParams.limit,
                page  : 1,
                search: 'no-search-term'
            });
        }

        // if enter pressed
        if (e.keyCode === 13 && e.target.value.length > 2) {
            onSubmitSearch(searchRef.current.value);
        }
    }

    function onSelectSortChange(e) {
        handleOnInventoryChange({ sort: e.value });
    }

    function onPageCountChange(e) {
        handleOnInventoryChange({ limit: e.value, page: 1 });
    }

    function handleOnClearSearch() {
        searchRef.current.value = '';
        handleOnInventoryChange({ search: 'no-search-term' });
    }

    function getLeftContent() {
        const sortByOptions = sortBy.options;
        const sortSelectStyles = {
            container: (provided) => ({
                ...provided,
                width: 225
            })
        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Label>SORT BY</Label>
                <Select
                    onChange={onSelectSortChange}
                    options={sortByOptions}
                    styles={sortSelectStyles}
                    defaultValue={{
                        value: sortBy.default,
                        label: sortBy.default
                    }}
                />
            </div>
        );
    }

    function getRitghContent() {
        return (
            <div
                style={{
                    display      : 'flex',
                    alignItems   : 'center',
                    flexDirection: 'row-reverse'
                }}
            >
                <Select
                    onChange={onPageCountChange}
                    styles={selectStyles}
                    defaultValue={{
                        value: defaultCount,
                        label: defaultCount
                    }}
                    options={countOptions}
                />
                <Label>Show</Label>
            </div>
        );
    }

    if (searchRef.current) {
        searchRef.current.value = queryParams.search || '';
    }

    return (
        <ToolbarWrapper>
            <Row>
                <Col md="4" sm="4" xs="12">
                    {getLeftContent()}
                </Col>
                <Col md="4" sm="4" xs="12">
                    <SearchBoxWrapper>
                        {
                            isEmpty(queryParams.search) ? (
                                <ButtonStyled color="link" onClick={() => { }}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </ButtonStyled>
                            ) : (
                                <ButtonStyled color="link" onClick={handleOnClearSearch}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </ButtonStyled>
                            )
                        }

                        <SearchBox
                            type="text"
                            defaultValue={queryParams.search || ''}
                            onKeyUp={onKeyUp}
                            placeholder={searchSchema.placeholder}
                            ref={searchRef}
                            className="form-control"
                        />
                        <Button className="btn btn-light border ml-1" onClick={onSubmitSearch}>
                            Search
                        </Button>
                    </SearchBoxWrapper>
                </Col>
                <Col md="4" sm="4" xs="12">
                    {getRitghContent()}
                </Col>
            </Row>
        </ToolbarWrapper>
    );
}

export default TableToolbar;
