import PropTypes from 'prop-types';
import React from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import { callFunc } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const AccordionStyled = styled.div`
    margin-bottom: 0px;
    padding: 5px 0;
`;
const AccordionHeader = styled.div`
    color: #333;
    cursor: pointer;
    padding: 5px 15px 0 18px;
    text-transform: uppercase;
    &:hover {
        // background: #f9f9f9;
        border-radius: 4px;
    }
`;

function Accordion(props) {
    const {
        id, content, title, collapse, onClick
    } = props;

    function onAccordionOpen() {
        callFunc(onClick());
    }

    return (
        <AccordionStyled isOpen={collapse}>
            <AccordionHeader onClick={onAccordionOpen} id={id}>
                <Row noGutters>
                    <Col md="11" xs="11">
                        {title}
                    </Col>
                    <Col md="1" xs="1">
                        <FontAwesomeIcon icon={collapse ? faChevronUp : faChevronDown} />
                    </Col>
                </Row>
            </AccordionHeader>
            <Collapse toggler={id} isOpen={collapse} style={{ marginTop: '5px' }}>
                {content}
            </Collapse>
        </AccordionStyled>
    );
}

Accordion.propTypes = {
    id      : PropTypes.string,
    content : PropTypes.any,
    title   : PropTypes.any,
    onClick : PropTypes.func,
    collapse: PropTypes.bool
};

Accordion.defaultProps = {
    id      : '__accordion',
    content : 'No content',
    title   : '',
    onClick : null,
    collapse: null
};

export default Accordion;
