import React from 'react';
import get from 'lodash.get';
import { TableHead, TableHeadStyled, TableRow } from './styled';

function TableHeader(props) {
    // eslint-disable-next-line react/prop-types
    const { schema } = props;
    const columns = get(schema, 'table.columns', []);
    return (
        <TableHeadStyled>
            <TableRow>
                {columns.map((column) => (
                    <TableHead key={`${column.label}__ID`}>
                        {column.label}
                    </TableHead>
                ))}
            </TableRow>
        </TableHeadStyled>
    );
}

export default TableHeader;
