// eslint-disable-next-line no-unused-vars
import React, { createContext } from 'react';

export const AppContext = createContext({});

export const reducer = (state, action) => {
    const { data, type } = action;
    switch (type) {
    case 'GET_APPLICATION_DATA_START':
        return {
            ...state,
            status: 'FETCHING'
        };
    case 'GET_APPLICATION_DATA_SUCCESS':
        return {
            ...state,
            subscription: data.subscription,
            business    : data.business,
            user        : data.user,
            realmUser   : data.realmUser,
            contactInfo : data.contactInfo,
            status      : 'FETCHING_DONE'
        };
    case 'GET_ITEMS_SUCCESS':
        return {
            ...state,
            items: {
                classifieds : state.items.classifieds.concat(data.classifieds),
                totalRecords: data.totalRecords,
                currentPage : data.currentPage
            },
            status: 'FETCHING_ITEMS_DONE'
        };
    case 'GET_USER_NOT_FOUND_INIT':
        return { ...state };
    case 'GET_USER_SUCCESS_INIT':
    case 'USER_LOGIN_SUCCESS':
        return {
            ...state,
            user: data
        };
    case 'USER_LOGOUT_SUCCESS':
        return {
            ...state,
            user: data
        };
    case 'USER_REGISTRATION_INIT':
        return {
            ...state,
            status: 'REGISTERING_USER'
        };
    case 'USER_REGISTRATION_SUCCESS':
        return {
            ...state,
            user: data
        };
    case 'REFRESH_BUSINESS_AND_SUBSCRIPTION':
        return {
            ...state,
            subscription: data.subscription,
            business    : data.business
        };
    default:
        return state;
    }
};

export default AppContext;
