import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    Row, Col, Spinner, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { PageTitle } from 'components';
import { APP_LANG } from 'constants/app';
import { Typography } from 'lib';
import InfiniteScroll from 'react-infinite-scroll-component';
import SETTINGS from 'constants/languages.json';
import get from 'lodash.get';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import NewItem from 'components/Item';
import { CATEGORIES } from 'constants/categories';
import FiltersModal from 'components/Modals/FiltersModal';
import Masonry from 'react-masonry-css';
import Filters from './Filters';

const DataGridStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;

    @media(max-width: 411px) {
        padding: 0;
    }
`;

const InfiniteScrollWrapper = styled.div`
    @media(min-width: 360px) {
        width: 100%;
    }
`;

function DataGrid(props) {
    const {
        data,
        hasMore,
        location,
        params,
        isLoading
    } = props;
    const [state, setState] = useState({ isOpen: false, openDrop: false });
    const urlQueryParams = queryString.parse(location.search);
    const category = CATEGORIES[urlQueryParams.category] || 'Search results';
    // handlers
    const setQuery = get(props, 'handlers.setQuery');

    function getLoader() {
        return <div className="text-center"><Spinner size="sm" color="secondary" /> {SETTINGS[APP_LANG].LOADING}</div>;
    }

    function getLatestItemsForSale() {
        const parsedQuery = queryString.stringify({
            ...params,
            ...urlQueryParams,
            page: parseInt(params.page, 10) + 1
        });
        setQuery(parsedQuery);
    }

    function getActions(item) {
        // const { handlers } = props;

        return {
            // onViewDetails: () => handlers.onViewDetails(item)
            onViewDetails: () => window.open(`/details/trucking/${item.id}`, '_blank')
        };
    }

    function toggle() {
        setState((prevState) => ({ ...prevState, isOpen: !state.isOpen }));
    }

    function getSortText() {
        let sort;
        switch (urlQueryParams.sort) {
        case 'i.title desc':
            sort = 'Name DESC (Z-A)';
            break;
        case 'i.title asc':
            sort = 'Name ASC (A-Z)';
            break;
        case 'i.price asc':
            sort = 'Price lowest to highest';
            break;
        case 'i.price desc':
            sort = 'Price highest to lowest';
            break;
        default:
            sort = 'Filters';
        }
        return sort;
    }

    function onItemClicked(selected) {
        const { handlers: { handleSetQuery } } = props;
        handleSetQuery({ subCategory: selected.value });
    }

    function getPageTitle() {
        const subCategoriesList = get(props, `schema.subCategories.${urlQueryParams.category}`, []);
        const subCategory = subCategoriesList.find((c) => c.value === urlQueryParams.subCategory);
        const hasCategories = subCategoriesList.length === 0;
        return (
            <>
                {isMobile ? (
                    <ButtonDropdown
                        className="pl-0"
                        disabled={hasCategories}
                        isOpen={state.openDrop}
                        toggle={
                            () => setState((prevState) => ({ ...prevState, openDrop: !state.openDrop }))
                        }
                    >
                        <DropdownToggle outline caret={!hasCategories} color="link">
                            {category} {subCategory && ` - ${subCategory.label}`}
                        </DropdownToggle>
                        <DropdownMenu>
                            {subCategoriesList.map((subcategory) => (
                                <DropdownItem key={subcategory.key} onClick={() => onItemClicked(subcategory)}>
                                    {subcategory.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </ButtonDropdown>
                ) : (
                    <div>
                        <Typography
                            fontWeight="bold"
                            color="#544c4c"
                        >
                            {category} {subCategory ? ` - ${subCategory.label}` : ' - All'}
                        </Typography>
                    </div>
                )}
            </>
        );
    }

    function renderContent() {
        if (isEmpty(data) && !isLoading) {
            return (
                <div>
                    <strong>No results in this category</strong>
                    <Typography type="a" href="/classifieds?category=trucking" marginLeft="5px">
                        Most recent
                    </Typography>
                </div>
            );
        }
        if (isLoading && !hasMore) {
            return (
                <div>
                    <Spinner size="sm" color="secondary" /> {SETTINGS[APP_LANG].LOADING}
                </div>
            );
        }
        return (
            <InfiniteScrollWrapper className="infinite-scroll-wrapper">
                <InfiniteScroll
                    hasMore={hasMore}
                    next={getLatestItemsForSale}
                    dataLength={data.length}
                    loader={getLoader()}
                >
                    <Masonry
                        breakpointCols={{
                            default: 4,
                            1100   : 3,
                            700    : 2,
                            500    : 1
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {data.map((item) => (
                            <NewItem
                                key={item.id}
                                data={item}
                                isMobile={isMobile}
                                actions={getActions(item)}
                                className="card-item mb-3"
                            />
                        ))}
                    </Masonry>
                </InfiniteScroll>
            </InfiniteScrollWrapper>
        );
    }

    return (
        <>
            <Row form>
                <Col md="4" lg="2" className={isMobile ? 'mb-1' : ''}>
                    <Filters {...props} />
                </Col>
                <Col md="8" sm="12" lg="10">
                    <DataGridStyled>
                        <PageTitle
                            title={getPageTitle()}
                            rightContent={getSortText()}
                            margin="10px 0"
                            open={toggle}
                        />
                        {renderContent()}
                    </DataGridStyled>
                </Col>
            </Row>
            <FiltersModal
                {...props}
                toggle={toggle}
                handleOnChange={(selected) => props.handlers.handleSetQuery({ sort: selected, page: 1 })}
                // handleOnSubmit={props.handlers.handleResetFilter}
                isOpen={state.isOpen}
                category={urlQueryParams.category}
                options={get(props, 'schema.sortBy.options', [])}
            />
        </>
    );
}

DataGrid.propTypes = {
    data        : PropTypes.array,
    badgeText   : PropTypes.string,
    hasMore     : PropTypes.bool,
    totalRecords: PropTypes.number,
    handlers    : PropTypes.object,
    location    : PropTypes.object,
    params      : PropTypes.object,
    isLoading   : PropTypes.bool
};

DataGrid.defaultProps = {
    data        : [],
    badgeText   : null,
    hasMore     : true,
    totalRecords: 0,
    handlers    : {},
    location    : {},
    params      : {},
    isLoading   : false
};

export default DataGrid;
