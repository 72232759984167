import React from 'react';
import PropTypes from 'prop-types';
import { Button as StrapButton, Spinner } from 'reactstrap';
import { Typography } from 'lib';

function Button(props) {
    const { color, label, spinner } = props;
    return (
        <StrapButton color={color}>
            {spinner && <Spinner {...spinner} /> }
            <Typography>{label}</Typography>
        </StrapButton>
    );
}

Button.propTypes = {
    color  : PropTypes.string,
    label  : PropTypes.string,
    spinner: PropTypes.object
};

Button.defaultProps = {
    color  : 'primary',
    label  : 'Submit',
    spinner: { color: 'primary', type: 'border' }
};

export default Button;
