import React, { useState, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import AdminContext from './Context';

const Favorites = lazy(() => import('./Favorites'));
const Create = lazy(() => import('./Create'));
const Account = lazy(() => import('./Account'));
const Inventory = lazy(() => import('./Inventory'));
const ChooseCategory = lazy(() => import('./Create/ChooseCategory'));

function Admin() {
    const [state, setState] = useState({});

    function updateBusinessInfo(info = {}) {
        const { businessInfo } = state;
        setState({ businessInfo: { ...businessInfo, ...info } });
    }

    return (
        <Row noGutters>
            <Col>
                <AdminContext.Provider value={{ ...state, updateBusinessInfo }}>
                    <Switch>
                        <Route exact path="/admin/inventory" component={Inventory} />
                        <Route path="/admin/account" component={Account} />
                        <Route path="/admin/inventory/:action" component={Create} />
                        <Route path="/admin/choose-category" component={ChooseCategory} />
                        <Route path="/admin/favorites" component={Favorites} />
                    </Switch>
                </AdminContext.Provider>
            </Col>
        </Row>
    );
}

Admin.propTypes = {};

export default Admin;
