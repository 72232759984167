import React, { useState } from 'react';
import styled from 'styled-components';
import Form from 'react-jsonschema-form';
import { Alert, Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import firebase from '../../../config/firebase';

const VerifyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    width: 50%;
    border: 1px solid #eee;
    border-radius: 4px;
    flex-direction: column;
    padding: 25px 20px;
    margin: 65px 10px 10px 10px;
    background: #fff;

    @media (max-width: 414px) {
        width: 100%;
    }
`;

function Recover() {
    const [emailSent, setEmailSent] = useState(false);

    function getFormSchema() {
        return {
            title     : 'Recover Password',
            type      : 'object',
            className : 'text-center',
            properties: {
                email: {
                    title: 'Your Email',
                    type : 'string'
                }
            },
            required: ['email']
        };
    }

    function getUISchema() {
        return {
            email: {
                'ui:widget': 'email',
                'ui:help'  : 'Check your "Spam" folder if you do not see the email in your inbox.'
            }
        };
    }

    function onSubmit(formData) {
        const {
            formData: { email }
        } = formData;

        // send the firebase email
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                // Email sent.
                setEmailSent(true);
            })
            .catch((error) => {
                const { code } = error;
                // no user found
                if (code === 'auth/user-not-found') {
                    // eslint-disable-next-line no-alert
                    window.alert(
                        'Unable to find your account. Please make sure you have a valid email.'
                    );
                }
            });
    }

    return (
        <Container>
            <VerifyWrapper>
                <Content id="recover-content">
                    {!emailSent && (
                        <Form
                            schema={getFormSchema()}
                            uiSchema={getUISchema()}
                            onSubmit={onSubmit}
                        >
                            <Button
                                color="danger"
                                className="text-white font-weight-bold"
                            >
                                Send
                            </Button>
                        </Form>
                    )}
                    {emailSent && (
                        <>
                            <Alert color="success" outline>
                                <FontAwesomeIcon icon={faCheck} /> We sent you an email with instructions on how to recover your password.{' '}
                                Please check your &quot;Spam folder&quot; if you do not see it in your inbox.
                            </Alert>
                            <Link to="/auth/login">Login</Link>
                        </>
                    )}
                </Content>
            </VerifyWrapper>
        </Container>
    );
}

export default Recover;
