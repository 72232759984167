import firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

// TODO: move this settings to env vars
const config = {
    apiKey           : 'AIzaSyA-WetE3bTcIT6BIPIO9yayaRk7zP2cLjc',
    authDomain       : 'trucking-9c0bd.firebaseapp.com',
    databaseURL      : 'https://trucking-9c0bd.firebaseio.com',
    projectId        : 'trucking-9c0bd',
    storageBucket    : 'trucking-9c0bd.appspot.com',
    messagingSenderId: '422393086515',
    measurementId    : 'G-GVKEFLDNGN',
    appId            : '1:422393086515:web:376f0fd50aed75ced3d946'
};

firebaseApp.initializeApp(config);

/**
 * firestore as the db
 */
const firestore = firebaseApp.firestore();

/**
 * Authentication
 */
const fireAuth = firebaseApp.auth();

/**
 * Storage
 */
const fireStorage = firebaseApp.storage();

/**
 * Analytics
 */
const analytics = firebaseApp.analytics();

/**
 * Default App
 */
export default firebaseApp;

export {
    firestore, fireAuth, fireStorage, analytics
};
