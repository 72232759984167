import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

function BreadCrumb(props) {
    const { location } = props;
    const queryParams = queryString.parse(location.search);
    const queryArr = Object.keys(queryParams);
    const queryParamCount = queryArr.length;
    let searchParams = '';
    let count = 0;
    return (
        <div>
            <Breadcrumb tag="nav" listTag="div">
                <BreadcrumbItem tag="a" href={location.pathname}>Home</BreadcrumbItem>
                {
                    queryArr.map((param) => {
                        searchParams += `${param}=${queryParams[param]}`;
                        // increment count
                        count += 1;
                        // if the array is not the last element, append `&`
                        if (count < queryParamCount) {
                            searchParams += '&';
                        }
                        const href = `${location.pathname}?${searchParams}`;

                        return (
                            <BreadcrumbItem tag="a" href={href}>
                                {queryParams[param]}
                            </BreadcrumbItem>
                        );
                    })
                }
            </Breadcrumb>
        </div>
    );
}

BreadCrumb.propTypes = {
    location: PropTypes.object
};

BreadCrumb.defaultProps = {
    location: window.location
};

export default BreadCrumb;
