/**
 * Return a string of parameters
 *
 * @param {params} params The object containing the api parameters
 * @return {String}
 */
export const buildParams = params => {
    let queryParams = '';
    const keys = Object.keys(params);
    keys.forEach(key => {
        if (params[key]) {
            queryParams += `${key}=${params[key]}&`;
        }
    });
    return queryParams.slice(0, -1);
};

export const p = () => {};
