import firebase from 'config/firebase';
import get from 'lodash.get';
import Axios from './http';

// Access Token
const accessToken = window.localStorage.getItem('accessToken');

Axios.interceptors.request.use(
    (config) => {
        if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)

);

let isRefreshing = false;
// response interceptor
Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = get(error, 'response.status');
        const data = get(error, 'response.data', []);
        const { config } = error;
        const originalRequest = config;
        // FIXME: Firebase returns the message below when the token has not
        // been renewed in a while. That's the only error returned
        if (data && data.message && data.message.includes('unable to lookup correct key')) {
            // because we may be in the middle of a process.
            window.location.href = `${window.location.protocol}//${window.location.host}`;
            window.localStorage.removeItem('accessToken');
            return;
        }

        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                firebase.auth().onAuthStateChanged((user) => {
                    if (user) {
                        isRefreshing = false;
                        const {
                            phoneNumber,
                            photoURL,
                            displayName,
                            emailVerified,
                            email,
                            uid
                        } = user;
                        window.localStorage.setItem(
                            'truckingData',
                            JSON.stringify({
                                phoneNumber,
                                photoURL,
                                displayName,
                                emailVerified,
                                email,
                                uid
                            })
                        );
                        user.getIdTokenResult().then((result) => {
                            // get the token
                            user.getIdToken().then((refreshedToken) => {
                                window.accessToken = refreshedToken;
                                // store a copy of the token local storage
                                window.localStorage.setItem('accessToken', refreshedToken);
                                // update the `truckingData` with claims
                                const userProfile = JSON.parse(window.localStorage.getItem('truckingData'));
                                // store back to localStorage
                                window.localStorage.setItem('truckingData', JSON.stringify({ ...userProfile, claims: { superAdmin: result.claims.superAdmin } }));
                                // update headers with new token
                                originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
                                // FIXME: Need to find a way to retry the call
                                window.location.reload();
                                // re-send the previous request
                                return Axios.request(error.config);
                            });
                        });
                    }
                });
            }
        }
        return Promise.reject(error);
    }
);

export default Axios;
