import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Row, Col, Alert } from 'reactstrap';
import { Divider } from 'lib';
import Table from 'lib/Table';
import Paginator from 'lib/Paginator/Paginator';
import TableToolbar from './TableToolbar';
import Filters from './Filters';

const DataTableWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-between;
    background: #fff;
    border: 1px solid #eee;
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
`;

function DataTable(props) {
    const { schema } = props;

    if (!schema) {
        return <Alert color="danger"><strong>DataTable</strong> requires a schema</Alert>;
    }

    return (
        <Row form>
            {schema.filters && (
                <Col md="2">
                    <Filters {...props} />
                </Col>
            )}
            <Col md={schema.filters ? '10' : '12'}>
                <DataTableWrapper id="data-table">
                    {schema.toolbar && (
                        <>
                            <TableToolbar {...props} />
                            <Divider />
                        </>
                    )}
                    <Table {...props} />
                    <Paginator {...props} />
                </DataTableWrapper>
            </Col>
        </Row>

    );
}

DataTable.propTypes = {
    schema: PropTypes.object.isRequired
};

export default DataTable;
