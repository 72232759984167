import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { APP_NAME } from 'constants/app';
import { Link } from 'react-router-dom';

const List = styled.ul`
    display: flex;
    place-content: space-evenly;
    list-style-type: none;
    @media(max-width: 550px) {
        flex-direction: column;
    }
`;

const ListInlineItem = styled.li`
    @media(max-width: 550px) {
        padding: 10px 0;
    }
`;

const FooterWrapper = styled.footer`
    border-top: 1px solid #eee;
`;

function Footer() {
    return (
        <FooterWrapper className="footer mt-auto py-3 bg-dark text-white">
            <Container>
                <List>
                    <ListInlineItem>&#169; {APP_NAME}, {new Date().getFullYear()}</ListInlineItem>
                    <ListInlineItem>
                        <Link to="/contact" className="text-light">Contact Us</Link>
                    </ListInlineItem>
                    <ListInlineItem>
                        <Link to="/terms" className="text-light">Terms of Service</Link>
                    </ListInlineItem>
                    <ListInlineItem>
                        <Link to="/privacy" className="text-light">Privacy Policy</Link>
                    </ListInlineItem>
                    <ListInlineItem>
                        <a href="https://www.facebook.com/343trucking" target="_blank" rel="noopener noreferrer" className="text-light">
                            Facebook
                        </a>
                    </ListInlineItem>
                </List>
            </Container>
        </FooterWrapper>
    );
}

export default Footer;
