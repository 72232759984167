import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { formatCurrensy } from 'utils';
import { isMobile } from 'react-device-detect';
import { Pointer, Offer } from 'components/Common/Offer';

const CardWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
`;

function NewItem(props) {
    const { data, actions, displayMillage } = props;
    const {
        title, descriptionTrimed, location, price, offer, primaryPhone, distance,
    } = data;
    let imageSrc;
    const hasOffer = Boolean(offer);
    const areWeSelling = data.category === 'for-sale' && ['truck'].includes(data.subCategory);
    if (areWeSelling) {
        if (data.images && data.images.length > 0) {
            imageSrc = data.images[0].url;
        }
    }

    function getCardSubTitle() {
        if (data.category === 'truck-jobs') {
            return <>{data.listingDetails.business.name} &bull; {location}</>;
        }
        return <>{location} {(distance && displayMillage) && <> &bull; {distance} mi</>}</>
    }

    return (
        <CardWrapper className="card" onClick={actions.onViewDetails}>
            {imageSrc && (
                <div>
                    <img src={imageSrc} className="card-img-top" alt={title} />
                </div>
            )}
            <div className="card-body">
                <div>
                    {data.isSponsored && <div className="badge badge-warning mr-1">Sponsored</div>}
                    {data.category === 'truck-jobs' && <div className="badge badge-secondary">Job</div>}
                </div>
                <p className="mb-1 card-title">{title}</p>
                <div className="mb-2 card-subtitle">
                    {getCardSubTitle()}
                </div>
                {areWeSelling && (
                    <>
                        <div className="fw-bold">
                            {formatCurrensy(price)}<br />
                        </div>
                        <a href={`tel:${primaryPhone}`} className="btn btn-outline-secondary btn-block mt-2" name="primaryPhone">
                            Call {primaryPhone}
                        </a>
                    </>
                )}
                {(descriptionTrimed && !areWeSelling) && (
                    <p className="card-text-truncate m-0">
                        {descriptionTrimed}
                    </p>
                )}
                {hasOffer && (
                    <Offer>
                        <Pointer className="pl-2">Offer</Pointer>
                        <div className="truncate" style={{ margin: '0 0 0 25px' }}>{offer || 'More details inside'}</div>
                    </Offer>
                )}
                {data?.category !== 'for-sale' && (
                    <div className="d-flex justify-content-between align-items-center pt-3">
                        <div className="col-6 pl-0 pr-1">
                            {(primaryPhone && isMobile) ? (
                                <a href={`tel:${primaryPhone}`} className="btn btn-dark btn-block" name="primaryPhone">
                                    <FontAwesomeIcon icon={faPhoneAlt} /> Call
                                </a>
                            ) : (
                                <div className="fs-16">{primaryPhone}</div>
                            )}
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <button className="btn btn-outline-secondary btn-block" type="button" onClick={() => {}}>
                                More info{' '}
                                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '12px' }} />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </CardWrapper>
    );
}

NewItem.propTypes = {
    data   : PropTypes.object,
    actions: PropTypes.object
};

NewItem.defaultProps = {
    data   : {},
    actions: () => {}
};

export default NewItem;
