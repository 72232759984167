/* eslint-disable react/prop-types */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, Spinner, ModalFooter
} from 'reactstrap';
import { toast } from 'react-toastify';

function ChangeLocation(props) {
    const [submitting, setIsSubmitting] = useState(false);
    const [displayLocationDenied, setDisplayLocationDenied] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('prompt');
    const { isOpen, toggle, onComplete } = props;

    useEffect(() => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            setPermissionStatus(result.state);
        });
    }, []);

    function locationSuccess(position) {
        try {
            const { coords } = position;
            const userLocation = JSON.stringify({
                lat: coords.latitude,
                lng: coords.longitude,
                status: 'granted'
            });
            window.localStorage.setItem('343UserLocation', userLocation);
            setIsSubmitting(false);
            onComplete();
        } catch (err) {
            toast.error('Sorry, we could not get your location. Please try again.');
            toggle();
        }
    }

    function locationError(error) {
        let message = '';
        switch (error.code) {
            case error.PERMISSION_DENIED:
                message = 'Your user settings denied access to your location. You can enable it via your browser or app settings.';
                break;
            case error.POSITION_UNAVAILABLE:
                message = 'Sorry, we could not get your location. Please try again.';
                break;
            case error.TIMEOUT:
                message = 'Sorry, we could not get your location. Please try again.';
                break;
            default:
                message = 'Sorry, we could not get your location. Please try again.';
                break;
        }
        setIsSubmitting(false);
        toast.error(message);
        toggle();
    }

    async function onSubmit() {
        try {
            if ('geolocation' in navigator) {
                setIsSubmitting(true);
                if (permissionStatus === 'granted') {
                    navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
                } else if (permissionStatus === 'prompt') {
                    navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
                } else if (permissionStatus === 'denied') {
                    setDisplayLocationDenied(true);
                }
            } else {
                throw new Error('Sorry, your device does not support location services.');
            }
        } catch (err) {
            // eslint-disable-next-line no-alert
            window.alert(err);
            setIsSubmitting(true);
        }
    }

    if (displayLocationDenied || permissionStatus === 'denied') {
        return (
            <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle}>
                    Location Services Disabled
                </ModalHeader>
                <ModalBody>
                    You can do so by going to your browser settings.{' '}
                    If you are using a mobile device, you can also enable location services in your device settings.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        localStorage.removeItem('343UserLocation');
                        localStorage.getItem('useDefaultLocation');
                        window.location.reload();
                    }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle} />
                <ModalBody>
                    {<div style={{ textAlign: 'center' }}>
                        <div className="mb-3 fs-18">
                            We use your location to show you services near you.
                        </div>
                        <div className="mb-3 fs-12">
                            Please note that at the moment, we only serve the following areas: <br />
                            The Greater Los Angeles Area and Surrounding Cities, more coming soon!
                        </div>
                        <Button
                            disabled={submitting}
                            onClick={onSubmit}
                            color="success"
                            className="text-white"
                        >
                            {!submitting ? (
                                <>
                                    {permissionStatus === 'granted' ? 'Update location' : 'Enable Location Services'}
                                </>
                            ) : (
                                <>
                                    <Spinner size="sm" /> One moment...
                                </>
                            )}
                        </Button>
                        <div className="mt-3 fs-12">
                            <div className="text-muted">
                                <strong>Disclaimer:</strong> We do not store your location
                            </div>
                        </div>
                    </div>}
                </ModalBody>
            </Modal>
        </div>
    );
}

ChangeLocation.propTypes = {
    isOpen : PropTypes.bool,
    toggle    : PropTypes.func,
    onComplete: PropTypes.func
};

ChangeLocation.defaultProps = {
    isOpen : false,
    toggle    : null,
    onComplete: () => {}
};

export default ChangeLocation;
